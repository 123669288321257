<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    <a class="active-view-button" routerLink="/mandator/mandator/overview">{{
      'COMMON.MANDATORS' | translate
    }}</a>
    <ng-container *ngIf="permissionService.userPermissions.CanManageOrgUnits">
      <ac-vertical-divider></ac-vertical-divider>
      <a routerLink="/mandator/organisation-unit/overview">{{
        'ORGANISATION_UNIT.TITLE' | translate
      }}</a>
    </ng-container>
  </div>
</mat-toolbar>
<mat-sidenav-container class="filter-sidenav-container">
  <mat-sidenav
    mode="side"
    class="main-side-nav"
    [fixedInViewport]="true"
    #overviewFilter
    [fixedTopGap]="130">
    <ac-overview-filter
      [displayedFilters]="displayedFilters"
      [pageSettings]="pageSettings$"
      (filter)="filtering()"></ac-overview-filter>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card class="mat-list-card">
      <div class="overview-table-with-filter mat-elevation-z8">
        <div class="common-filter-padding">
          <mat-form-field>
            <!--todo ac-setup reset on action-->
            <input
              matInput
              (keyup)="filterTable($event)"
              [value]="this.pageSettings.overviewFilter.tableGlobalFilter"
              placeholder="{{ 'USERS.FILTER.FILTER' | translate }}" />
          </mat-form-field>
        </div>
        <div>
          <mat-table
            [dataSource]="dataSource"
            matSort
            [matSortActive]="pageSettings.sortSetting.active"
            [matSortDirection]="pageSettings.sortSetting.direction"
            matSortDisableClear
            (matSortChange)="changeSort($event)">
            <ng-container [matColumnDef]="displayedColumns[0]">
              <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>
                {{ 'MANDATORS.SHORT_NAME' | translate }}
              </mat-header-cell>
              <mat-cell fxFlex="15" class="table-cell-bold-text-pointer" *matCellDef="let row"
                ><span (click)="navigateToEdit(row.Id)"> {{ row.ShortName }} </span></mat-cell
              >
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[1]">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'MANDATORS.NAME' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.Name }} </mat-cell>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[2]">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'MANDATORS.CONFIGURED_ORIGINS' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.ConfiguredOrigins }} </mat-cell>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[3]">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'MANDATORS.CONFIGURED_TRACERS' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.ConfiguredTracers }} </mat-cell>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[4]">
              <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>
                {{ 'MANDATORS.TRADE_TYPES' | translate }}
              </mat-header-cell>
              <mat-cell fxFlex="15" *matCellDef="let row">
                {{ mapTradeTypesToCode(row.TradeTypes) }}
              </mat-cell>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[5]">
              <mat-header-cell fxFlex="2" *matHeaderCellDef mat-sort-header>
                {{ 'COMMON.IS_ACTIVE' | translate }}
              </mat-header-cell>
              <mat-cell fxFlex="2" *matCellDef="let row"> {{ row.IsActive }} </mat-cell>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[6]">
              <mat-header-cell fxFlex="2" *matHeaderCellDef></mat-header-cell>
              <mat-cell fxFlex="2" *matCellDef="let row">
                <a
                  class="table-actions cursor-pointer"
                  fxLayoutAlign="end center"
                  [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </a>
                <mat-menu #menu="matMenu">
                  <button *ngIf="row.IsActive" mat-menu-item (click)="deactivateMandator(row.Id)">
                    <span>{{ 'COMMON.DEACTIVATE' | translate }}</span>
                  </button>
                  <button *ngIf="!row.IsActive" mat-menu-item (click)="activateMandator(row.Id)">
                    <span>{{ 'COMMON.ACTIVATE' | translate }}</span>
                  </button>
                  <button mat-menu-item (click)="navigateToEdit(row.Id)">
                    <span>{{ 'COMMON.EDIT' | translate }}</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[9]">
              <mat-header-cell fxFlex="2" *matHeaderCellDef></mat-header-cell>
              <mat-cell fxFlex="2" *matCellDef="let row">
                <a
                  class="table-actions cursor-pointer"
                  fxLayoutAlign="end center"
                  [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </a>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="navigateToEdit(row.Id)">
                    <span>{{ 'USERS.EDIT' | translate }}</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ deactivated: !row.IsActive }">
            </mat-row>
          </mat-table>

          <mat-paginator
            [pageSize]="pageSettings.pagingSetting.pageSize"
            [pageIndex]="pageSettings.pagingSetting.pageIndex"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="changePaging($event)"></mat-paginator>
        </div>
      </div>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
