/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EmailTemplateViewModel { 
    Id?: number;
    TemplateId?: string;
    MandatorIds?: Array<number>;
    Title?: string;
    SenderEmail?: string;
    Language?: EmailTemplateViewModel.LanguageEnum;
    TradeTypeCodes?: Array<string>;
    Trigger?: EmailTemplateViewModel.TriggerEnum;
    ShowForNbo?: boolean;
    ShowForBo?: boolean;
    ShowForProject?: boolean;
    Scopes?: Array<EmailTemplateViewModel.ScopesEnum>;
    AdditionalReceivers?: Array<string>;
}
export namespace EmailTemplateViewModel {
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
    export type TriggerEnum = 'PasswordReset' | 'LeadAssigned' | 'DefaultNbo' | 'DefaultBo' | 'DefaultProject' | 'NboLeadCreated' | 'ImageUploaded' | 'MtSapIntegration' | 'MtCustomerNotification' | 'ProjectArchived' | 'TaskAssigned' | 'DebitorAddressAdded' | 'ObjectAddressAdded' | 'ServiceTechnicianAddressAdded' | 'CarDealerAddressAdded' | 'HeatingInstallerAddressAdded' | 'LeadDelegated' | 'AddExternalManagedContact' | 'SapDebtorError' | 'SynchronizationError' | 'LeadTaskChanged' | 'ProjectStepCompleted' | 'ProjectWon' | 'ProjectAssigned' | 'ProjectInProgress' | 'ProjectInOperation' | 'ProjectAccounted';
    export const TriggerEnum = {
        PasswordReset: 'PasswordReset' as TriggerEnum,
        LeadAssigned: 'LeadAssigned' as TriggerEnum,
        DefaultNbo: 'DefaultNbo' as TriggerEnum,
        DefaultBo: 'DefaultBo' as TriggerEnum,
        DefaultProject: 'DefaultProject' as TriggerEnum,
        NboLeadCreated: 'NboLeadCreated' as TriggerEnum,
        ImageUploaded: 'ImageUploaded' as TriggerEnum,
        MtSapIntegration: 'MtSapIntegration' as TriggerEnum,
        MtCustomerNotification: 'MtCustomerNotification' as TriggerEnum,
        ProjectArchived: 'ProjectArchived' as TriggerEnum,
        TaskAssigned: 'TaskAssigned' as TriggerEnum,
        DebitorAddressAdded: 'DebitorAddressAdded' as TriggerEnum,
        ObjectAddressAdded: 'ObjectAddressAdded' as TriggerEnum,
        ServiceTechnicianAddressAdded: 'ServiceTechnicianAddressAdded' as TriggerEnum,
        CarDealerAddressAdded: 'CarDealerAddressAdded' as TriggerEnum,
        HeatingInstallerAddressAdded: 'HeatingInstallerAddressAdded' as TriggerEnum,
        LeadDelegated: 'LeadDelegated' as TriggerEnum,
        AddExternalManagedContact: 'AddExternalManagedContact' as TriggerEnum,
        SapDebtorError: 'SapDebtorError' as TriggerEnum,
        SynchronizationError: 'SynchronizationError' as TriggerEnum,
        LeadTaskChanged: 'LeadTaskChanged' as TriggerEnum,
        ProjectStepCompleted: 'ProjectStepCompleted' as TriggerEnum,
        ProjectWon: 'ProjectWon' as TriggerEnum,
        ProjectAssigned: 'ProjectAssigned' as TriggerEnum,
        ProjectInProgress: 'ProjectInProgress' as TriggerEnum,
        ProjectInOperation: 'ProjectInOperation' as TriggerEnum,
        ProjectAccounted: 'ProjectAccounted' as TriggerEnum
    };
    export type ScopesEnum = 'Variant' | 'Lead';
    export const ScopesEnum = {
        Variant: 'Variant' as ScopesEnum,
        Lead: 'Lead' as ScopesEnum
    };
}
