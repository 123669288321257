import { SetPageSettingsAction } from './../../../../shared/store/shared.actions';
import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MandatorViewModel, TradeTypeViewModel } from '../../../../shared/apis/advis';
import {
  ActivateMandatorAction,
  DeactivateMandatorAction,
  GetMandatorsAction,
} from '../../../state/mandator.action';
import { IRootState } from '../../../../root.state';
import { MandatorOverviewVsModel } from '../../../../shared/models/view-setting/user-overview-vs.model';
import { Router } from '@angular/router';
import { IPageSettings } from '../../../../shared/interfaces/Settings';
import { MatSort, Sort } from '@angular/material/sort';
import { OverviewBaseComponent } from '../../../../shared/components/overview-filter/overviewBaseComponent';
import { OverviewControl } from '../../../../shared/components/overview-filter/Type';
import { getMandatorsFiltered } from '../../../state/mandator.selectors';
import { PermissionService } from '../../../../shared/services/permission.service';
import { filter } from 'rxjs/operators';
import { DialogService } from '../../../../shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ac-mandator-overview',
  templateUrl: './mandator-overview.component.html',
  styleUrls: ['./mandator-overview.component.scss'],
})
export class MandatorOverviewComponent extends OverviewBaseComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  mandatorsFiltered: MandatorViewModel[] = [];

  displayedColumns: string[] = [
    'Name',
    'ShortName',
    'ConfiguredOrigins',
    'ConfiguredTracers',
    'TradeTypes',
    'IsActive',
    'Actions',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  mandatorViewSettings: MandatorOverviewVsModel = new MandatorOverviewVsModel();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageSettings: IPageSettings;

  constructor(
    store: Store<IRootState>,
    private router: Router,
    public permissionService: PermissionService,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.refresh();

    this.overviewFilterInit(
      reflectComponentType(MandatorOverviewComponent).selector,
      [
        { key: 'Name', translateKey: 'Name', control: OverviewControl.STRING, isServerSide: true },
        {
          key: 'ShortName',
          translateKey: 'Short name',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'ConfiguredOrigins',
          translateKey: 'Configured origins',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'TradeTypes',
          translateKey: 'Trade types',
          control: OverviewControl.SELECT,
          isServerSide: true,
          options: [],
        },
      ],
      this.subscription
    );

    this.subscription.add(
      this.store.select(getMandatorsFiltered).subscribe((mandators: MandatorViewModel[]) => {
        this.mandatorsFiltered = mandators;
        this.dataSource = new MatTableDataSource(this.mandatorsFiltered);
        this.dataSource.paginator = this.paginator;

        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            default:
              return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.clientSideFiltering();
      })
    );
  }

  mapTradeTypesToCode(tradeTypes: TradeTypeViewModel[]): string {
    return tradeTypes.map((x: TradeTypeViewModel) => x.Code).join(', ');
  }

  changePaging(e: PageEvent): void {
    this.pageSettings.pagingSetting.pageIndex = e.pageIndex;
    this.pageSettings.pagingSetting.pageSize = e.pageSize;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  changeSort(e: Sort): void {
    this.pageSettings.sortSetting = e;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  refresh(): void {
    this.store.dispatch(new GetMandatorsAction());
  }

  navigateToEdit(mandatorId: string): void {
    this.router.navigate(['/mandator/mandator/edit', mandatorId]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPagingChange(event: PageEvent): void {
    this.mandatorViewSettings.pageSize = event.pageSize;
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(): void {
    // empty
  }

  deactivateMandator(mandatorId: number): void {
    this.subscription.add(
      this.dialogService
        .openConfirm(
          this.translate.instant('MANDATORS.CONFIRM_DEACTIVATE'),
          this.translate.instant('DIALOG.TITLE_CONFIRM')
        )
        .afterClosed()
        .pipe(filter((confirm: boolean) => !!confirm))
        .subscribe(() => this.store.dispatch(new DeactivateMandatorAction(mandatorId)))
    );
  }

  activateMandator(mandatorId: number): void {
    this.subscription.add(
      this.dialogService
        .openConfirm(
          this.translate.instant('MANDATORS.CONFIRM_ACTIVATE'),
          this.translate.instant('DIALOG.TITLE_CONFIRM')
        )
        .afterClosed()
        .pipe(filter((confirm: boolean) => !!confirm))
        .subscribe(() => this.store.dispatch(new ActivateMandatorAction(mandatorId)))
    );
  }
}
