<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ formTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <h2>
      {{ formTitle }}
    </h2>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="heatGeneratorForm"
        #heatGeneratorForm="ngForm"
        *ngIf="heatGenerator || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.ID' | translate }}"
              type="text"
              name="id"
              required
              [disabled]="!isNew"
              [(ngModel)]="heatGenerator.Id"
              #id />
            <mat-hint>{{ 'HT_MASTERDATA.NO_SPACES_DOTS_AND_SLASHES_IN_ID' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.ARTICLE_NUMBER' | translate }}"
              type="text"
              name="articleNumber"
              required
              [(ngModel)]="heatGenerator.ArticleNumber"
              #articleNumber />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox id="isActive" name="isActive" [(ngModel)]="heatGenerator.IsActive">{{
            'COMMON.IS_ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.TECHNICAL_TITLE' | translate }}"
              type="text"
              name="technicalTitle"
              required
              [(ngModel)]="heatGenerator.TechnicalTitle"
              #technicalTitle />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.DESC' | translate }}"
              type="text"
              name="description"
              [(ngModel)]="heatGenerator.Description"
              #description />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'HT_MASTERDATA.HEAT_GENERATORS.HEATING_TYPE' | translate }}</mat-label>

            <mat-select
              name="heatType"
              required
              [(ngModel)]="heatGenerator.HeatingType"
              #heatType
              (selectionChange)="onHeatingTypeChange($event)">
              <mat-option *ngFor="let heatingType of heatingTypes" [value]="heatingType">{{
                heatingType
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <h4>{{ 'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER' | translate }}</h4>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_A7W35' | translate
              }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerA7W35"
              [(ngModel)]="heatGenerator.MaxHeatingPowerA7W35"
              [required]="heatingPowersRequiredness['maxHeatingPowerA7W35']"
              #maxHeatingPowerA7W35 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_A7W55' | translate
              }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerA7W55"
              [(ngModel)]="heatGenerator.MaxHeatingPowerA7W55"
              [required]="heatingPowersRequiredness['maxHeatingPowerA7W55']"
              #maxHeatingPowerA7W55 />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_B0W35' | translate
              }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerB0W35"
              [(ngModel)]="heatGenerator.MaxHeatingPowerB0W35"
              [required]="heatingPowersRequiredness['maxHeatingPowerB0W35']"
              #maxHeatingPowerB0W35 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_B0W55' | translate
              }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerB0W55"
              [(ngModel)]="heatGenerator.MaxHeatingPowerB0W55"
              [required]="heatingPowersRequiredness['maxHeatingPowerB0W55']"
              #maxHeatingPowerB0W55 />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_COOLING_POWER_B0W35' | translate
              }}"
              type="number"
              [min]="0"
              name="maxCoolingPowerB0W35"
              [(ngModel)]="heatGenerator.MaxCoolingPowerB0W35"
              [required]="heatingPowersRequiredness['maxCoolingPowerB0W35']" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Cooling Function</mat-label>
            <mat-select
              name="heatGeneratorCoolingFunction"
              required
              [(ngModel)]="heatGenerator.CoolingFunction"
              #heatGeneratorCoolingFunction>
              <mat-option
                *ngFor="let coolingFunctionType of coolingFunctionTypes"
                [value]="coolingFunctionType"
                >{{ coolingFunctionType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_GAS_P5030' | translate
              }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerGasP5030"
              [(ngModel)]="heatGenerator.MaxHeatingPowerGasP5030"
              [required]="heatingPowersRequiredness['maxHeatingPowerGasP5030']"
              #maxHeatingPowerGasP5030 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_OIL_5530' | translate
              }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerOil5530"
              [(ngModel)]="heatGenerator.MaxHeatingPowerOil5530"
              [required]="heatingPowersRequiredness['maxHeatingPowerOil5530']"
              #maxHeatingPowerOil5530 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.MAX_HEATING_POWER_WOOD' | translate }}"
              type="number"
              [min]="0"
              name="maxHeatingPowerWood"
              [(ngModel)]="heatGenerator.MaxHeatingPowerWood"
              [required]="heatingPowersRequiredness['maxHeatingPowerWood']"
              #maxHeatingPowerWood />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MIN_REQUIRED_REGISTER_SIZE' | translate
              }}"
              type="number"
              [min]="0"
              name="minRequiredRegisterSize"
              required
              [(ngModel)]="heatGenerator.MinRequiredRegisterSize"
              #minRequiredRegisterSize />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.MAX_REQUIRED_REGISTER_SIZE' | translate
              }}"
              type="number"
              [min]="0"
              name="maxRequiredRegisterSize"
              required
              [(ngModel)]="heatGenerator.MaxRequiredRegisterSize"
              #maxRequiredRegisterSize />
          </mat-form-field>
        </div>
        <h4>{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL' | translate }}</h4>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES1Q2' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES1Q2"
              [(ngModel)]="heatGenerator.NoiseLevelES1Q2"
              #noiseLevelES1Q2 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES1Q4' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES1Q4"
              [(ngModel)]="heatGenerator.NoiseLevelES1Q4"
              #noiseLevelES1Q4 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES1Q8' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES1Q8"
              [(ngModel)]="heatGenerator.NoiseLevelES1Q8"
              #noiseLevelES1Q8 />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES2Q2' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES2Q2"
              [(ngModel)]="heatGenerator.NoiseLevelES2Q2"
              #noiseLevelES2Q2 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES2Q4' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES2Q4"
              [(ngModel)]="heatGenerator.NoiseLevelES2Q4"
              #noiseLevelES2Q4 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES2Q8' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES2Q8"
              [(ngModel)]="heatGenerator.NoiseLevelES2Q8"
              #noiseLevelES2Q8 />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES3Q2' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES3Q2"
              [(ngModel)]="heatGenerator.NoiseLevelES3Q2"
              #noiseLevelES3Q2 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES3Q4' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES3Q4"
              [(ngModel)]="heatGenerator.NoiseLevelES3Q4"
              #noiseLevelES3Q4 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES3Q8' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES3Q8"
              [(ngModel)]="heatGenerator.NoiseLevelES3Q8"
              #noiseLevelES3Q8 />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES4Q2' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES4Q2"
              [(ngModel)]="heatGenerator.NoiseLevelES4Q2"
              #noiseLevelES4Q2 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES4Q4' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES4Q4"
              [(ngModel)]="heatGenerator.NoiseLevelES4Q4"
              #noiseLevelES4Q4 />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.NOISE_LEVEL_ES4Q8' | translate }}"
              type="number"
              [min]="0"
              name="noiseLevelES4Q8"
              [(ngModel)]="heatGenerator.NoiseLevelES4Q8"
              #noiseLevelES4Q8 />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

            <mat-select
              name="mandatorIds"
              multiple
              required
              [(ngModel)]="heatGenerator.MandatorIds"
              #mandatorIds>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'HT_MASTERDATA.SUPPORTED_MANUFACTURES' | translate }}</mat-label>
            <mat-select
              name="supportedManufacturersMap"
              required
              [(ngModel)]="heatGenerator.Manufacturer"
              #supportedManufacturersMap>
              <mat-option
                *ngFor="let manufacturer of supportedManufacturers"
                [value]="manufacturer"
                >{{ manufacturer }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'HT_MASTERDATA.SUB_PRODUCT_CONNECTION' | translate }}</mat-label>
            <mat-select
              name="subProductConnection"
              [(ngModel)]="heatGenerator.SubProductConnectionType"
              #subProductConnectionType>
              <mat-option [value]="undefined">
                {{ 'HT_MASTERDATA.SUB_PRODUCT_CONNECTION_EMPTY' | translate }}
              </mat-option>
              <mat-option
                *ngFor="let connection of subProductConnectionTypes"
                [value]="connection.key">
                {{ connection.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h4>{{ 'HT_MASTERDATA.HEAT_GENERATORS.DOMESTIC_WATER_HEATING_TITLE' | translate }}</h4>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              required
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.DOMESTIC_WATER_HEATING_MIN_VOLUME' | translate
              }}"
              type="number"
              [min]="0"
              name="domesticWaterHeatingMinVolume"
              [(ngModel)]="heatGenerator.DomesticWaterHeatingMinVolume"
              #domesticWaterHeatingMinVolume />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              required
              placeholder="{{
                'HT_MASTERDATA.HEAT_GENERATORS.DOMESTIC_WATER_HEATING_MAX_VOLUME' | translate
              }}"
              type="number"
              [min]="0"
              name="domesticWaterHeatingMaxVolume"
              [(ngModel)]="heatGenerator.DomesticWaterHeatingMaxVolume"
              #domesticWaterHeatingMaxVolume />
          </mat-form-field>
        </div>

        <h4>{{ 'HT_MASTERDATA.HEAT_GENERATORS.WATER_TANKS_TITLE' | translate }}</h4>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              required
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.WATER_TANKS_MIN_VOLUME' | translate }}"
              type="number"
              [min]="0"
              name="waterTanksMinVolume"
              [(ngModel)]="heatGenerator.WaterTanksMinVolume"
              #waterTanksMinVolume />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              required
              placeholder="{{ 'HT_MASTERDATA.HEAT_GENERATORS.WATER_TANKS_MAX_VOLUME' | translate }}"
              type="number"
              [min]="0"
              name="waterTanksMaxVolume"
              [(ngModel)]="heatGenerator.WaterTanksMaxVolume"
              #waterTanksMaxVolume />
          </mat-form-field>
        </div>

        <h4>Translation</h4>
        <ac-localized-values
          [localizedValues]="heatGenerator.LocalizedTitles"
          title="COMMON.TRANSLATION">
        </ac-localized-values>
        <ac-localized-values
          [localizedValues]="heatGenerator.LocalizedDataSheets"
          [title]="'COMMON.DATASHEET'"
          [required]="false"
          [setReadonly]="true">
        </ac-localized-values>

        <ac-ht-masterdata-string-generation-controls
          [dialogData]="heatGenerator.StringGenerationContainer"
          [required]="false"
          [mandators]="mandators"
          [preSelectedMandatorIds]="heatGenerator.MandatorIds"
          [onSanitizeXssForSaveCallback]="onSaveStringGenerationSanitizeCallback"
          [displayGeneratedString]="false"
          (dialogClosedEvent)="onStringGenerationDialogClosed($event)">
        </ac-ht-masterdata-string-generation-controls>

        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'COMMON.AR_VIEWER_URL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.AR_VIEWER_URL' | translate }}"
              type="text"
              name="arViewerUrl"
              [maxLength]="2048"
              [(ngModel)]="heatGenerator.ArViewerUrl" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
