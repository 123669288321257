<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ 'PROJECT_REPORTING.FORM_TITLE' | translate }}
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <h2>
      {{ 'PROJECT_REPORTING.FORM_TITLE' | translate }}
    </h2>
    <mat-card-content>
      <div>
        <ac-file-select
          #financesFileSelect
          (onFileSelect)="onFinancesFileSelect($event)"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></ac-file-select>
        <button
          mat-icon-button
          name="ImportFinances"
          (click)="importFinances()"
          [disabled]="!financesFile">
          <mat-icon>cloud_upload</mat-icon>
          {{ 'PROJECT_REPORTING.UPLOAD_FINANCES' | translate }}
        </button>
      </div>
      <div *ngIf="progressMessages && progressMessages.length > 0">
        <h2>{{ 'COMMON.PROGRESS' | translate }}</h2>
        <ul>
          <li *ngFor="let message of progressMessages">
            {{ message.Timestamp | date : 'dd.MM.yy H:mm' }}: {{ message.Message }}
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</div>
