import { Component, Inject, OnInit } from '@angular/core';
import {
  LocalizedValueViewModel,
  MandatorViewModel,
  StringGeneration,
  StringGenerationBlock,
  StringGenerationContainer,
} from '../../apis/advis';

import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import LanguageEnum = LocalizedValueViewModel.LanguageEnum;
import BlockTypeEnum = StringGenerationBlock.BlockTypeEnum;

import { NotificationService } from '../../services/notification.service';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

@Component({
  selector: 'ac-string-generation-dialog',
  templateUrl: './string-generation-dialog.component.html',
  styleUrls: ['./string-generation-dialog.component.sass'],
})
export class StringGenerationComponent implements OnInit {
  protected readonly isNullOrUndefined = isNullOrUndefined;

  blocks: StringGenerationBlock[] = [];
  currentLanguage: string = 'de';
  mandators: MandatorViewModel[] = [];
  currentMandatorId: number = null;

  generatedStringPreview: string = '';
  mandatorBaseUrl: string = '';

  constructor(
    public dialogRef: MatDialogRef<StringGenerationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      language: string;
      mandators: MandatorViewModel[];
      dialogData: StringGenerationContainer;
      preSelectedMandatorIds: number[];
    },
    private notificationService: NotificationService
  ) {
    this.currentLanguage = dialogData.language;

    if (dialogData.dialogData) {
      if (dialogData.preSelectedMandatorIds && dialogData.preSelectedMandatorIds.length > 0) {
        this.mandators = dialogData.mandators.filter(m =>
          dialogData.preSelectedMandatorIds.includes(m.Id)
        );

        if (this.mandators.length > 0) {
          this.currentMandatorId = this.mandators[0].Id;
        }
      }

      switch (this.currentLanguage) {
        case LanguageEnum.EN:
          this.mapExistingData(dialogData.dialogData.English);
          break;
        case LanguageEnum.DE:
          this.mapExistingData(dialogData.dialogData.German);
          break;
        case LanguageEnum.FR:
          this.mapExistingData(dialogData.dialogData.French);
          break;
        case LanguageEnum.IT:
          this.mapExistingData(dialogData.dialogData.Italian);
          break;
        default:
          console.log('Language not supported: ' + this.currentLanguage);
      }

      this.updateMandatorBaseUrl();
    }
  }

  updateMandatorBaseUrl() {
    if (this.currentMandatorId) {
      let mandator = this.mandators.find(m => m.Id == this.currentMandatorId);
      if (mandator) {
        this.mandatorBaseUrl = mandator.StringGenerationBaseUrl;

        if (this.mandatorBaseUrl) {
          if (!this.mandatorBaseUrl.startsWith('http')) {
            this.mandatorBaseUrl = 'https://' + this.mandatorBaseUrl;
          }

          while (this.mandatorBaseUrl.endsWith('/')) {
            this.mandatorBaseUrl = this.mandatorBaseUrl.substring(
              0,
              this.mandatorBaseUrl.length - 1
            );
          }
        }
      }
    }
  }

  mapExistingData(stringGeneration: StringGeneration) {
    if (stringGeneration) {
      this.blocks = stringGeneration.Blocks;
      this.currentLanguage = stringGeneration.Language;
      this.currentMandatorId = parseInt(stringGeneration.MandatorId);

      this.generateStringPreview();

      if (stringGeneration.RawData) {
        this.generatedStringPreview = stringGeneration.RawData;
      }
    }
  }

  ngOnInit(): void {}

  addBlock() {
    this.blocks.push({
      BlockType: BlockTypeEnum.Text,
      Content: '',
      AdditionalContent: '',
    });
  }

  removeBlock = (index: number): void => {
    this.blocks.splice(index, 1);
  };

  generateStringPreview() {
    this.generatedStringPreview = '';

    if (isNullOrUndefined(this.mandatorBaseUrl)) {
      this.alertNoBaseUrlConfigured();
      return;
    }

    this.updateMandatorBaseUrl();

    for (let i = 0; i < this.blocks.length; i++) {
      if (i > 0 && i < this.blocks.length) {
        this.generatedStringPreview += ' ';
      }

      switch (this.blocks[i].BlockType) {
        case BlockTypeEnum.Text:
          this.generatedStringPreview += this.blocks[i].Content;
          break;
        case BlockTypeEnum.Link:
          this.blocks[i].Content = this.sanitizeUrlsForPreview(this.blocks[i].Content);
          this.generatedStringPreview +=
            '<a href="' +
            this.mandatorBaseUrl +
            this.blocks[i].Content +
            '" target="_blank" rel="noopener noreferrer"' +
            '>' +
            this.blocks[i].AdditionalContent +
            '</a>';
          break;
        case BlockTypeEnum.BreakLine:
          this.generatedStringPreview += '<br>';
          break;
        default:
          console.log('BlockType not supported: ' + this.blocks[i].BlockType);
          break;
      }
    }
    this.generatedStringPreview = this.generatedStringPreview.trim();
  }

  sanitizeUrlsForPreview(input: string): string {
    while (input.startsWith('/')) {
      input = input.substring(1);
    }

    input = '/' + input;

    while (input.endsWith('/')) {
      input = input.substring(0, input.length - 1);
    }

    return input.trim();
  }

  build(): StringGeneration {
    return {
      Blocks: this.blocks,
      Language: this.currentLanguage,
      MandatorId: this.currentMandatorId.toString(),
      RawData: this.generatedStringPreview,
    } as StringGeneration;
  }

  accept(): void {
    this.generateStringPreview();

    this.dialogRef.close(this.build());
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  onMandatorChange($event: MatSelectChange) {
    let mandator = this.mandators.find(m => m.Id == $event.value);

    this.currentMandatorId = mandator.Id;

    if (isNullOrUndefined(mandator.StringGenerationBaseUrl)) {
      this.alertNoBaseUrlConfigured();
    }

    this.mandatorBaseUrl = mandator.StringGenerationBaseUrl;
    this.generateStringPreview();
  }

  alertNoBaseUrlConfigured() {
    const mandatorName = this.mandators.find(m => m.Id == this.currentMandatorId).Name;
    if (mandatorName) {
      this.notificationService.notifySimple('No Base URL configured for Mandator: ' + mandatorName);
    } else {
      this.notificationService.notifySimple(
        'Could not find mandator with the provided ID. Please contact your administrator.'
      );
    }
  }
}
