<mat-form-field class="demo-full-width margin-top" [formGroup]="group">
  <mat-select
    #matSelect
    [required]="field.IsRequired"
    [placeholder]="field.NameAlias || field.Name"
    [formControlName]="field.Name">
    <mat-option *ngIf="!field.IsRequired && matSelect.value">None</mat-option>
    <mat-option *ngFor="let item of field.Values" [value]="item">{{ item }}</mat-option>
  </mat-select>
</mat-form-field>
