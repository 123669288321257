import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDECH from '@angular/common/locales/de-CH';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule, StoreDevtoolsOptions } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { MainComponent } from './main/main.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ApiModule, BASE_PATH, Configuration } from './shared/apis/advis';
import { AppInitializerGuard } from './shared/guards/app-initializer.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { SpinnerInterceptor } from './shared/interceptors/spinner.interceptor';
import { DeactivationGuard } from './shared/services/deactivation.guard';
import { DialogService } from './shared/services/dialog.service';
import { EnvironmentService } from './shared/services/environment.service';
import { NotificationService } from './shared/services/notification.service';
import { PermissionService } from './shared/services/permission.service';
import { SpinnerService } from './shared/services/spinner.service';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { MandatorModule } from './mandator/mandator.module';
import { PvMasterdataModule } from './pv-masterdata/pv-masterdata.module';
import { HtMasterdataModule } from './ht-masterdata/ht-masterdata.module';
import { ProjectReportingModule } from './project-reporting/project-reporting.module';
import { KeepAliveInterceptor } from './shared/interceptors/keep-alive.interceptor';
import { HomeComponent } from './home/home.component';
import { StringGenerationMapperService } from './shared/components/string-generation-dialog/string-generation-mapper.service';
import { SignalRInterceptor } from './shared/interceptors/signalr.interceptor';

registerLocaleData(localeDECH);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // FIXME: Enable when use base-href, see: https://github.com/ngx-translate/http-loader/pull/12
  // return new TranslateHttpLoader(http, '/pc/assets/i18n/'); // **PROD-BUILD**
  // Simple cache buster - just add the version. Should be good enough in production.
  return new TranslateHttpLoader(http, undefined, '.json?v=' + environment.version);
}

const metaReducers: any = environment.production ? [] : [];

@NgModule({
  declarations: [AppComponent, MainComponent, MaintenanceComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    AuthModule,
    SharedModule,
    PvMasterdataModule,
    HtMasterdataModule,
    ProjectReportingModule,
    UserModule,
    MandatorModule,
    ApiModule.forRoot(apiConfigurationFactory),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    // READ THAT! you must instrument after importing StoreModule
    environment.instrumentStoreDevtools
      ? StoreDevtoolsModule.instrument({ maxAge: 50 } as StoreDevtoolsOptions)
      : [],
  ],
  providers: [
    EnvironmentService,
    AuthGuard,
    AppInitializerGuard,
    DeactivationGuard,
    PermissionService,
    NotificationService,
    SpinnerService,
    DialogService,
    StringGenerationMapperService,
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: BASE_PATH, useValue: environment.apiBasePath },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: KeepAliveInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SignalRInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 700, hideDelay: 0 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

/**
 * Preload language to use 'instant' method of the translation service
 * Ref: https://github.com/ngx-translate/core/issues/517
 * @param {TranslateService} translate
 * @param {ViewSettingService} vsService
 * @param {Injector} injector
 * @returns {any}
 */
export function appInitializerFactory(translate: TranslateService, injector: Injector): any {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized: any = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(undefined)
      );
      locationInitialized.then(() => {
        const langToSet: string = 'en';
        translate.setDefaultLang('en');
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err: any) => {
            console.log('err', err);
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(undefined);
          }
        );
      });
    });
}

export function apiConfigurationFactory(): Configuration {
  return new Configuration({ withCredentials: true });
}
