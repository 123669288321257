import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { IGuardDeactivation } from '../interfaces/guard-deactivation';
import { Observable } from 'rxjs';
import { DialogService } from './dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { first, switchMap, tap } from 'rxjs/operators';

/**
 * Guard that helps with components that have editors with unsaved changes. The component
 * which is guarded by this guard needs to implement the IGuardDeactivation interface.
 *
 * When a navigation occurrs this guard checks the component if there are any unsaved changes
 * or if the user can savely navigate away without losing changes. If there are open changes,
 * a confirmation dialog is shown. If no message key is defined by the component, the default
 * message COMMON.DATA_LOST is used instead.
 */
@Injectable()
export class DeactivationGuard implements CanDeactivate<IGuardDeactivation> {
  constructor(private dialogs: DialogService, private translate: TranslateService) {}

  canDeactivate(component: IGuardDeactivation): boolean | Observable<boolean> | Promise<boolean> {
    if (component.canDeactivateSafely) {
      return true;
    }
    return this.translate.get(component.deactivateConfirmationMessage || 'COMMON.DATA_LOST').pipe(
      first(),
      switchMap((message: any) =>
        this.dialogs
          .openConfirm(message)
          .afterClosed()
          .pipe(tap((result: boolean) => console.log('Confirm close result: ', result)))
      )
    );
  }
}
