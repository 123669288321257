import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PermissionService } from '../../../../shared/services/permission.service';
import { IRootState } from '../../../../root.state';
import {
  MandatorViewModel,
  OrganisationUnitViewModel,
  UserRequest,
  UserViewModel,
} from '../../../../shared/apis/advis';
import { OverviewBaseComponent } from '../../../../shared/components/overview-filter/overviewBaseComponent';
import {
  IOverviewFilterDefine,
  OverviewControl,
} from '../../../../shared/components/overview-filter/Type';
import { IOverviewFilter, IPageSettings } from '../../../../shared/interfaces/Settings';
import { MandatorOverviewVsModel } from '../../../../shared/models/view-setting/user-overview-vs.model';
import {
  getMandators,
  getOrganisationUnits,
  getRoles,
  getSelectableMandators,
  IMandatorSelectItem,
} from '../../../../shared/store';
import { CommonUtil } from '../../../../shared/utils/common.util';
import * as UserAction from '../../../state/user/user.action';
import {
  ActivateUsersAction,
  DeactivateUsersAction,
  GetUsersAction,
} from '../../../state/user/user.action';
import { getUsersFiltered } from '../../../state/user/user.selectors';
import {
  SetPageSettingsAction,
  ToggleSelectMultipleTradetypeCodes,
} from './../../../../shared/store/shared.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ac-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss'],
})
export class UserOverviewComponent extends OverviewBaseComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  usersFiltered: UserViewModel[] = [];

  displayedColumns: string[] = [
    'UserName',
    'FirstName',
    'LastName',
    'Email',
    'MandatorId',
    'MandatorsInResponsibility',
    'OrganisationUnitId',
    'Roles',
    'IsActive',
    'Actions',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  organisationUnits: OrganisationUnitViewModel[] = [];
  mandators: MandatorViewModel[] = [];
  selectedMandators: MandatorViewModel[];

  userViewSettings: MandatorOverviewVsModel = new MandatorOverviewVsModel();
  userFilter: UserRequest = {} as UserRequest;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  getMandatorNamesForId: (mandatorIds: number[], mandators: MandatorViewModel[]) => string =
    CommonUtil.getMandatorNamesForId;
  pageSettings: IPageSettings;

  constructor(
    store: Store<IRootState>,
    private router: Router,
    private action$: Actions,
    private snackBar: MatSnackBar,
    public permissionService: PermissionService
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.overviewFilterInit(
      reflectComponentType(UserOverviewComponent).selector,
      [
        {
          key: 'UserName',
          translateKey: 'Username',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'Email',
          translateKey: 'Email',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        { key: 'Name', translateKey: 'Name', control: OverviewControl.STRING, isServerSide: true },
        {
          key: 'OrganisationUnitId',
          translateKey: 'Organisation Unit',
          control: OverviewControl.SELECT,
          isServerSide: true,
          options: [],
        },
        {
          key: 'Role',
          translateKey: 'Role',
          control: OverviewControl.SELECT,
          isServerSide: true,
          options: [],
        },
        {
          key: 'IsActive',
          translateKey: 'Is Active',
          control: OverviewControl.SELECT,
          isServerSide: true,
          options: [
            { key: 'True', id: 'True' },
            { key: 'False', id: 'False' },
          ],
        },
      ],
      this.subscription
    );

    this.store.dispatch(new ToggleSelectMultipleTradetypeCodes(true));

    this.subscription.add(
      this.store.select(getUsersFiltered).subscribe((users: UserViewModel[]) => {
        this.usersFiltered = users;
        this.dataSource = new MatTableDataSource(this.usersFiltered);
        this.dataSource.paginator = this.paginator;

        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            default:
              return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.clientSideFiltering();
      })
    );

    const selectedMandatorViewModel$: Observable<IMandatorSelectItem[]> =
      this.store.select(getSelectableMandators);

    this.subscription.add(
      combineLatest(selectedMandatorViewModel$).subscribe(([selectedMandators]: any) => {
        // Not sure if the requirement to filter for mandators locally comes back soon - so keep the selectedMandators here
        this.selectedMandators = selectedMandators
          .filter((mandatorSelectItem: IMandatorSelectItem) => mandatorSelectItem.selected)
          .map((item: IMandatorSelectItem) => item.mandatorVm);

        if (this.selectedMandators === undefined || this.selectedMandators.length === 0) {
          this.dataSource = new MatTableDataSource([]);
          return;
        }

        // Serverside filtering
        this.serverSideFiltering(
          this.pageSettings.overviewFilter.activeFilter.filter(
            (serverSideFilter: IOverviewFilter) => serverSideFilter.isServerSideFiltering === true
          )
        );

        this.refresh();
      })
    );

    this.subscription.add(
      this.store.select(getOrganisationUnits).subscribe((orgUnits: OrganisationUnitViewModel[]) => {
        this.organisationUnits = orgUnits;
        this.displayedFilters.find(
          (filter: IOverviewFilterDefine) => filter.key === 'OrganisationUnitId'
        ).options = orgUnits
          .filter(ou => ou.IsActive)
          .map((org: OrganisationUnitViewModel) => ({
            id: org.Id.toString(),
            key: org.Name,
          }));
      })
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators;
      })
    );

    this.subscription.add(
      this.store.select(getRoles).subscribe((roles: string[]) => {
        this.displayedFilters.find(
          (filter: IOverviewFilterDefine) => filter.key === 'Role'
        ).options = roles.map((role: string) => ({ id: role, key: role }));
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(UserAction.ADD_USER_SUCCESS), take(1)).subscribe(() => {
        this.refresh();
        this.snackBar.open('User added', 'Ok', { duration: 3000, panelClass: 'snackbar-primary' });
      })
    );
  }

  changePaging(e: PageEvent): void {
    this.pageSettings.pagingSetting.pageIndex = e.pageIndex;
    this.pageSettings.pagingSetting.pageSize = e.pageSize;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  changeSort(e: Sort): void {
    this.pageSettings.sortSetting = e;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  deactivateUser(userId: string): void {
    this.store.dispatch(new DeactivateUsersAction(userId));
  }

  activateUser(userId: string): void {
    this.store.dispatch(new ActivateUsersAction(userId));
  }

  refresh(): void {
    this.store.dispatch(new GetUsersAction(this.userFilter));
  }

  getOrgUnitNameForId(oeId: number): string {
    const orgUnit: OrganisationUnitViewModel = this.organisationUnits.find(
      (x: OrganisationUnitViewModel) => x.Id === oeId
    );
    return orgUnit
      ? `<span ${orgUnit.IsActive ? '' : 'class="deactivated-color"'}>${orgUnit.Name}</span>`
      : '';
  }

  getMandatorNameForId(mandatorId: number): string {
    const mandator: MandatorViewModel = this.mandators.find(
      (x: MandatorViewModel) => x.Id === mandatorId
    );
    return mandator ? mandator.Name : '';
  }

  getMandatorActiveForId(mandatorId: number): boolean {
    const mandator: MandatorViewModel = this.mandators.find(
      (x: MandatorViewModel) => x.Id === mandatorId
    );
    return mandator ? mandator.IsActive : true;
  }

  navigateToUserAdd(): void {
    this.router.navigate(['/user/user/add']);
  }

  navigateToEditAdd(userId: string): void {
    this.router.navigate(['/user/user/edit', userId]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPagingChange(event: PageEvent): void {
    this.userViewSettings.pageSize = event.pageSize;
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(filters: IOverviewFilter[]): void {
    this.userFilter = {} as UserRequest;

    filters.forEach((filter: IOverviewFilter) => (this.userFilter[filter.key] = filter.value));

    this.userFilter.MandatorIds = this.selectedMandators
      .map((mandator: MandatorViewModel) => mandator.Id)
      .join(';');

    this.refresh();
  }
}
