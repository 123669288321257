<div class="main-container">
  <mat-toolbar
    class="mat-toolbar main-header {{ debugInfo.debugClass }}"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" fxLayout="row">
        <img
          alt="Image"
          src="../../assets/images/theia_black.svg"
          class="application-logo margin-top-10" />
      </a>
      <h1 class="application-title">Admin Client</h1>
      <mat-chip-list class="debug-chip hide-mobile" *ngIf="debugInfo.isDisplayed">
        <mat-chip>{{ debugInfo.debugText }}</mat-chip>
      </mat-chip-list>
    </div>
    <div fxLayout="row">
      <ac-mandator-selector></ac-mandator-selector>
      <ac-trade-type-selector></ac-trade-type-selector>
      <div style="font-size: 16px">
        {{ currentUser }}
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="main-side-nav-container" [style.marginTop.px]="65">
    <mat-sidenav class="main-side-nav" [fixedInViewport]="true" #snav [fixedTopGap]="65">
      <mat-nav-list>
        <div>
          <a mat-list-item routerLink="/login" (click)="onLogout()">
            <mat-icon>exit_to_app</mat-icon>
            {{ 'MAIN.LOGOUT' | translate }}
          </a>
          <hr />
          <h4>{{ 'MAIN.ADMINISTRATION' | translate }}</h4>
          <a
            *ngIf="
              permissionService.userPermissions.CanManageUsers ||
              permissionService.userPermissions.CanManageOrgUnits
            "
            mat-list-item
            routerLink="/user"
            (click)="snav.close()">
            <mat-icon>person</mat-icon>
            {{ 'MAIN.USERS' | translate }}
          </a>
          <a
            *ngIf="permissionService.userPermissions.CanManageRoles"
            mat-list-item
            routerLink="/user/role/overview"
            (click)="snav.close()">
            <mat-icon>person</mat-icon>
            {{ 'MAIN.ROLES' | translate }}
          </a>
          <a
            *ngIf="
              permissionService.userPermissions.CanCreateMandators ||
              permissionService.userPermissions.CanManageMandators
            "
            mat-list-item
            routerLink="/mandator"
            (click)="snav.close()">
            <mat-icon>business</mat-icon>
            {{ 'MAIN.MANDATOR' | translate }}
          </a>
          <a
            *ngIf="permissionService.userPermissions.CanManageDocumentTemplates"
            mat-list-item
            routerLink="/document"
            (click)="snav.close()">
            <mat-icon>file_copy</mat-icon>
            {{ 'MAIN.TEMPLATES' | translate }}
          </a>
          <a
            *ngIf="permissionService.userPermissions.CanManageEmailTemplates"
            mat-list-item
            routerLink="/emailtemplates"
            (click)="snav.close()">
            <mat-icon>email</mat-icon>
            {{ 'MAIN.EMAILTEMPLATES' | translate }}
          </a>
          <a
            *ngIf="permissionService.userPermissions.CanManageProcess"
            mat-list-item
            routerLink="/process-configuration"
            (click)="snav.close()">
            <mat-icon>settings</mat-icon>
            {{ 'MAIN.PROCESS_CONFIGURATION' | translate }}
          </a>
          <a
            *ngIf="
              permissionService.userPermissions.CanManageProjectChecklistTemplates ||
              permissionService.userPermissions.CanManageSOP
            "
            mat-list-item
            routerLink="/project-checklist"
            (click)="snav.close()">
            <mat-icon>check_circle</mat-icon>
            {{ 'MAIN.PROJECT_CHECKLIST_TEMPLATES' | translate }}
          </a>
          <a
            *ngIf="mandators.length >= 1 && permissionService.userPermissions.CanManageProductlines"
            mat-list-item
            routerLink="/administration/product-lines/overview"
            (click)="snav.close()">
            <mat-icon>layers</mat-icon>
            {{ 'MAIN.PRODUCTLINES' | translate }}
          </a>
          <a
            *ngIf="mandators.length >= 1 && permissionService.userPermissions.CanManageRules"
            mat-list-item
            routerLink="/administration/position-builder/overview"
            (click)="snav.close()">
            <mat-icon>build</mat-icon>
            {{ 'MAIN.POSITIONBUILDER' | translate }}
          </a>
          <a
            *ngIf="mandators.length > 1"
            mat-list-item
            routerLink="/administration/position-group-template/overview"
            (click)="snav.close()">
            <mat-icon>file_copy</mat-icon>
            {{ 'MAIN.POSITION_GROUP_TEMPLATE' | translate }}
          </a>
          <a
            *ngIf="hasTradeTypePv && permissionService.userPermissions.CanManagePvMasterData"
            mat-list-item
            routerLink="/pvmasterdata/modules/overview"
            (click)="snav.close()">
            <mat-icon>wb_sunny</mat-icon>
            {{ 'MAIN.PV_MASTERDATA' | translate }}
          </a>
          <a
            *ngIf="hasTradeTypeHt && permissionService.userPermissions.CanManageHtMasterData"
            mat-list-item
            routerLink="/ht-masterdata/domestic-water-heating/overview"
            (click)="snav.close()">
            <mat-icon>waves</mat-icon>
            {{ 'MAIN.HT_MASTERDATA' | translate }}
          </a>
          <a
            *ngIf="hasTradeTypeEm && permissionService.userPermissions.CanManageEmMasterData"
            mat-list-item
            routerLink="/em-masterdata/charging-stations/overview"
            (click)="snav.close()">
            <mat-icon>electric_car</mat-icon>
            {{ 'MAIN.EM_MASTERDATA' | translate }}
          </a>
          <a
            mat-list-item
            routerLink="/administration/position-dependency-builder/overview"
            (click)="snav.close()"
            *ngIf="mandators.length >= 1 && permissionService.userPermissions.CanManageRules">
            <mat-icon>create</mat-icon>
            {{ 'MAIN.DEPENDENCYBUILDER' | translate }}
          </a>
          <a
            mat-list-item
            routerLink="/igh-theia-converter"
            (click)="snav.close()"
            *ngIf="permissionService.userPermissions.CanExcelPort">
            <mat-icon>import_export</mat-icon>
            {{ 'MAIN.IGH_THEIA_CONVERTER' | translate }}
          </a>
          <a
            mat-list-item
            routerLink="/copy"
            (click)="snav.close()"
            *ngIf="
              mandators.length >= 1 && permissionService.userPermissions.CanManageProductlines
            ">
            <mat-icon>file_copy</mat-icon>
            {{ 'MAIN.COPY' | translate }}
          </a>
          <a
            mat-list-item
            routerLink="/regional-information"
            (click)="snav.close()"
            *ngIf="permissionService.userPermissions.CanManageRegionalInformation">
            <mat-icon>import_export</mat-icon>
            {{ 'MAIN.REGIONAL_INFORMATION_EXCELPORT' | translate }}
          </a>
          <a
            mat-list-item
            routerLink="/excelport"
            (click)="snav.close()"
            *ngIf="permissionService.userPermissions.CanExcelPort">
            <mat-icon>import_export</mat-icon>
            {{ 'MAIN.EXCEL_PORT' | translate }}
          </a>
          <a
            mat-list-item
            routerLink="/project-reporting"
            (click)="snav.close()"
            *ngIf="permissionService.userPermissions.CanImportProjectReportingData">
            <mat-icon>import_export</mat-icon>
            {{ 'MAIN.PROJECT_REPORTING' | translate }}
          </a>
          <a
            *ngIf="permissionService.userPermissions.CanClearBlueOfficeCache"
            mat-list-item
            (click)="resetBoCache(); snav.close()">
            <mat-icon>clear</mat-icon>
            {{ 'MAIN.RESET_BO_CACHE' | translate }}
          </a>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet> </router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
