import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  MandatorViewModel,
  RoleTargetGroupViewModel,
  RoleViewModel,
} from '../../../shared/apis/advis';
import {
  IOverviewFilterDefine,
  OverviewControl,
} from '../../../shared/components/overview-filter/Type';
import { IPageSettings } from '../../../shared/interfaces/Settings';
import { RoleOverviewVsModel } from '../../../shared/models/view-setting/role-overview-vs.model';
import {
  getMandators,
  getSelectableMandators,
  IMandatorSelectItem,
  ToggleSelectMultipleTradetypeCodes,
} from '../../../shared/store';
import { CommonUtil } from '../../../shared/utils/common.util';
import { IRootState } from '../../../root.state';
import { OverviewBaseComponent } from '../../../shared/components/overview-filter/overviewBaseComponent';
import {
  DeleteRoleAction,
  DELETE_ROLE_SUCCESS,
  GetAllPermissionsAction,
  GetRolesAction,
  GetTargetGroupsAction,
  GetTargetGroupsSuccessAction,
  GET_TARGET_GROUPS_SUCCESS,
} from '../../state/role/role.action';
import { getAllPermissions, getRolesFiltered } from '../../state/role/role.selectors';
import { DialogService } from '../../../shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../../shared/services/permission.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleViewModelWithTargetGroup } from '../../../shared/apis/advis/model/roleViewModelWithTargetGroup';

@Component({
  selector: 'ac-role-overview',
  templateUrl: './role-overview.component.html',
  styleUrls: ['./role-overview.component.sass'],
})
export class RoleOverviewComponent extends OverviewBaseComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  rolesFiltered: RoleViewModelWithTargetGroup[] = [];
  permissions: string[] = [];

  displayedColumns: string[] = [
    'Name',
    'Permissions',
    'MandatorIds',
    'UsagesCount',
    'IsEnabledForNotesTargetGroup',
    'Actions',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  mandators: MandatorViewModel[] = [];
  selectedMandators: MandatorViewModel[];

  roleViewSettings: RoleOverviewVsModel = new RoleOverviewVsModel();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  getMandatorNamesForId: (mandatorIds: number[], mandators: MandatorViewModel[]) => string =
    CommonUtil.getMandatorNamesForId;
  getPermissions: (permissions: string[]) => string = permissions => permissions?.join(', ') || '';
  pageSettings: IPageSettings;

  protected targetGroups = new Array<RoleTargetGroupViewModel>();

  constructor(
    store: Store<IRootState>,
    private router: Router,
    private action$: Actions,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private translateService: TranslateService,
    public permissionService: PermissionService
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAllPermissionsAction());

    this.overviewFilterInit(
      reflectComponentType(RoleOverviewComponent).selector,
      [
        { key: 'Name', translateKey: 'Name', control: OverviewControl.STRING, isServerSide: false },
        {
          key: 'Permissions',
          translateKey: 'Permissions',
          control: OverviewControl.SELECT,
          isServerSide: false,
        },
        {
          key: 'UsagesCount',
          translateKey: 'UsagesCount',
          control: OverviewControl.NUMBER,
          isServerSide: false,
        },
        {
          key: 'MandatorIds',
          translateKey: 'MandatorIds',
          control: OverviewControl.SELECT,
          isServerSide: false,
        },
      ],
      this.subscription
    );

    this.store.dispatch(new ToggleSelectMultipleTradetypeCodes(true));

    this.subscription.add(
      combineLatest([
        this.store.select(getRolesFiltered),
        this.action$.pipe(ofType(GET_TARGET_GROUPS_SUCCESS)),
      ]).subscribe(([roles, action]: [RoleViewModel[], GetTargetGroupsSuccessAction]) => {
        this.targetGroups = action.payload;
        this.rolesFiltered = roles.map((role: RoleViewModelWithTargetGroup) => {
          const targetGroup = this.targetGroups.find(tg => tg.RoleId === role.Id);
          var IsEnabledForNotesTargetGroup = targetGroup
            ? targetGroup.IsEnabledForTargetGroup
            : false;
          return { ...role, IsEnabledForNotesTargetGroup };
        });

        this.dataSource = new MatTableDataSource(this.rolesFiltered);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          return item[property];
        };
        this.dataSource.sort = this.sort;
        this.clientSideFiltering();
      })
    );
    this.store.dispatch(new GetTargetGroupsAction());

    const selectedMandatorViewModel$: Observable<IMandatorSelectItem[]> =
      this.store.select(getSelectableMandators);

    this.subscription.add(
      combineLatest(selectedMandatorViewModel$).subscribe(([selectedMandators]: any) => {
        this.selectedMandators = selectedMandators
          .filter((mandatorSelectItem: IMandatorSelectItem) => mandatorSelectItem.selected)
          .map((item: IMandatorSelectItem) => item.mandatorVm);

        if (this.selectedMandators === undefined || this.selectedMandators.length === 0) {
          this.dataSource = new MatTableDataSource([]);
          return;
        }

        this.refresh();
      })
    );

    this.subscription.add(
      this.store.select(getAllPermissions).subscribe((permissions: string[]) => {
        this.permissions = permissions;
        this.displayedFilters.find(
          (filter: IOverviewFilterDefine) => filter.key === 'Permissions'
        ).options = permissions
          .map((permission: string) => ({ id: permission, key: permission }))
          .sort((a, b) => a.id.localeCompare(b.id));
      })
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators;
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(DELETE_ROLE_SUCCESS), take(1)).subscribe(() => {
        this.refresh();
        this.snackBar.open(
          this.translateService.instant('ROLE.SUCCESSFULLY_REMOVED'),
          this.translateService.instant('COMMON.BTN_OK'),
          { duration: 3000, panelClass: 'snackbar-primary' }
        );
      })
    );
  }

  changePaging(event: PageEvent): void {
    this.roleViewSettings.pageSize = event.pageSize;
  }

  refresh(): void {
    this.store.dispatch(new GetRolesAction());
  }

  getMandatorNameForId(mandatorId: number): string {
    const mandator: MandatorViewModel = this.mandators.find(
      (x: MandatorViewModel) => x.Id === mandatorId
    );
    return mandator ? mandator.Name : '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPagingChange(event: PageEvent): void {
    this.roleViewSettings.pageSize = event.pageSize;
  }

  navigateToRoleAdd(): void {
    this.router.navigate(['/user/role/add']);
  }

  navigateToEditAdd(roleId: string): void {
    this.router.navigate(['/user/role/edit', roleId]);
  }

  deleteRole(roleId: string): void {
    this.subscription.add(
      this.dialogService
        .openConfirm('ROLE.CONFIRN_DELETE', this.translateService.instant('DIALOG.TITLE_CONFIRM'))
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.store.dispatch(new DeleteRoleAction(roleId));
          }
        })
    );
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(): void {
    // empty
  }
}
