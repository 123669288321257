/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EmailTemplatesRequest { 
    Title?: string;
    Language?: EmailTemplatesRequest.LanguageEnum;
    MandatorIds?: string;
    TradeTypeCodes?: string;
    ShowForNbo?: boolean;
    ShowForBo?: boolean;
    ShowForProject?: boolean;
    Trigger?: EmailTemplatesRequest.TriggerEnum;
    PageIndex?: number;
    PageSize?: number;
    SortField?: EmailTemplatesRequest.SortFieldEnum;
    SortOrder?: EmailTemplatesRequest.SortOrderEnum;
}
export namespace EmailTemplatesRequest {
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
    export type TriggerEnum = 'PasswordReset' | 'LeadAssigned' | 'DefaultNbo' | 'DefaultBo' | 'DefaultProject' | 'NboLeadCreated' | 'ImageUploaded' | 'MtSapIntegration' | 'MtCustomerNotification' | 'ProjectArchived' | 'TaskAssigned' | 'DebitorAddressAdded' | 'ObjectAddressAdded' | 'ServiceTechnicianAddressAdded' | 'CarDealerAddressAdded' | 'HeatingInstallerAddressAdded' | 'LeadDelegated' | 'AddExternalManagedContact' | 'SapDebtorError' | 'SynchronizationError' | 'LeadTaskChanged' | 'ProjectStepCompleted' | 'ProjectWon' | 'ProjectAssigned' | 'ProjectInProgress' | 'ProjectInOperation' | 'ProjectAccounted';
    export const TriggerEnum = {
        PasswordReset: 'PasswordReset' as TriggerEnum,
        LeadAssigned: 'LeadAssigned' as TriggerEnum,
        DefaultNbo: 'DefaultNbo' as TriggerEnum,
        DefaultBo: 'DefaultBo' as TriggerEnum,
        DefaultProject: 'DefaultProject' as TriggerEnum,
        NboLeadCreated: 'NboLeadCreated' as TriggerEnum,
        ImageUploaded: 'ImageUploaded' as TriggerEnum,
        MtSapIntegration: 'MtSapIntegration' as TriggerEnum,
        MtCustomerNotification: 'MtCustomerNotification' as TriggerEnum,
        ProjectArchived: 'ProjectArchived' as TriggerEnum,
        TaskAssigned: 'TaskAssigned' as TriggerEnum,
        DebitorAddressAdded: 'DebitorAddressAdded' as TriggerEnum,
        ObjectAddressAdded: 'ObjectAddressAdded' as TriggerEnum,
        ServiceTechnicianAddressAdded: 'ServiceTechnicianAddressAdded' as TriggerEnum,
        CarDealerAddressAdded: 'CarDealerAddressAdded' as TriggerEnum,
        HeatingInstallerAddressAdded: 'HeatingInstallerAddressAdded' as TriggerEnum,
        LeadDelegated: 'LeadDelegated' as TriggerEnum,
        AddExternalManagedContact: 'AddExternalManagedContact' as TriggerEnum,
        SapDebtorError: 'SapDebtorError' as TriggerEnum,
        SynchronizationError: 'SynchronizationError' as TriggerEnum,
        LeadTaskChanged: 'LeadTaskChanged' as TriggerEnum,
        ProjectStepCompleted: 'ProjectStepCompleted' as TriggerEnum,
        ProjectWon: 'ProjectWon' as TriggerEnum,
        ProjectAssigned: 'ProjectAssigned' as TriggerEnum,
        ProjectInProgress: 'ProjectInProgress' as TriggerEnum,
        ProjectInOperation: 'ProjectInOperation' as TriggerEnum,
        ProjectAccounted: 'ProjectAccounted' as TriggerEnum
    };
    export type SortFieldEnum = 'None' | 'Id' | 'Title' | 'Language' | 'ShowForProject' | 'ShowForBo' | 'ShowForNbo' | 'Trigger';
    export const SortFieldEnum = {
        None: 'None' as SortFieldEnum,
        Id: 'Id' as SortFieldEnum,
        Title: 'Title' as SortFieldEnum,
        Language: 'Language' as SortFieldEnum,
        ShowForProject: 'ShowForProject' as SortFieldEnum,
        ShowForBo: 'ShowForBo' as SortFieldEnum,
        ShowForNbo: 'ShowForNbo' as SortFieldEnum,
        Trigger: 'Trigger' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
