<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ moduleFormTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <h2>
      {{ moduleFormTitle }}
    </h2>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="moduleForm"
        #moduleForm="ngForm"
        *ngIf="module || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.ID' | translate }}"
              type="text"
              name="id"
              required
              [disabled]="!isNew"
              [(ngModel)]="module.Id"
              #id />
            <mat-hint>{{ 'HT_MASTERDATA.NO_SPACES_DOTS_AND_SLASHES_IN_ID' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.ARTICLE_NUMBER' | translate }}"
              type="text"
              name="articleNumber"
              required
              [(ngModel)]="module.ArticleNumber"
              #articleNumber />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox id="isActive" name="isActive" [(ngModel)]="module.IsActive">{{
            'COMMON.IS_ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.DESC' | translate }}"
              type="text"
              name="description"
              required
              [(ngModel)]="module.Description"
              #description />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MODULES.COLOR' | translate }}"
              type="text"
              name="color"
              required
              [(ngModel)]="module.Color"
              #color />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MODULES.WIDTH' | translate }}"
              type="number"
              name="width"
              required
              [(ngModel)]="module.Width"
              #width />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MODULES.LENGTH' | translate }}"
              type="number"
              name="length"
              required
              [(ngModel)]="module.Length"
              #length />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MODULES.HEIGHT' | translate }}"
              type="number"
              name="height"
              required
              [(ngModel)]="module.Height"
              #height />
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MODULES.WATT_PEAK' | translate }}"
              type="number"
              name="watt"
              required
              [(ngModel)]="module.WattPeak"
              #watt />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label>Mounting type</mat-label>

            <mat-select name="mountingType" [(ngModel)]="module.MountingType" #mountingType>
              <mat-option *ngIf="module.MountingType">None</mat-option>
              <mat-option *ngFor="let x of mountingTypes" [value]="x">{{ x }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Price category</mat-label>

            <mat-select name="priceCategory" [(ngModel)]="module.PriceCategory" #priceCategory>
              <mat-option *ngIf="module.PriceCategory">None</mat-option>
              <mat-option *ngFor="let x of priceCategories" [value]="x">{{ x }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'COMMON.ETURNITY_COMPONENT_ID' | translate }}</mat-label>

            <input
              matInput
              placeholder="{{ 'COMMON.ETURNITY_COMPONENT_ID' | translate }}"
              type="text"
              name="eturnityComponentId"
              [maxLength]="100"
              [(ngModel)]="module.EturnityComponentId" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'COMMON.ORDER' | translate }}</mat-label>

            <input
              matInput
              placeholder="{{ 'COMMON.ORDER' | translate }}"
              type="number"
              name="eturnityComponentId"
              [(ngModel)]="module.Order" />
          </mat-form-field>
        </div>
        <ac-localized-values
          [localizedValues]="module.LocalizedTitles"
          [title]="'COMMON.TRANSLATION'">
        </ac-localized-values>

        <ac-localized-values
          [localizedValues]="module.LocalizedDataSheets"
          [title]="'COMMON.DATASHEET'">
        </ac-localized-values>
        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

            <mat-select name="mandatorIds" multiple [(ngModel)]="module.MandatorIds" #mandatorIds>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'COMMON.AR_VIEWER_URL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.AR_VIEWER_URL' | translate }}"
              type="text"
              name="arViewerUrl"
              [maxLength]="2048"
              [(ngModel)]="module.ArViewerUrl" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
