import { Component, OnInit, ViewChild } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import {
  PostPutHeatGeneratorRequest,
  HeatingSubProductConnectionViewModel,
  HeatGeneratorViewModel,
  StringGeneration,
  LocalizedValueViewModel,
} from '../../../../../shared/apis/advis';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../../../root.state';
import { take } from 'rxjs/operators';
import { IGuardDeactivation } from '../../../../../shared/interfaces/guard-deactivation';
import {
  ADD_HEAT_GENERATOR_SUCCESS,
  AddHeatGeneratorAction,
  EDIT_HEAT_GENERATOR_SUCCESS,
  EditHeatGeneratorAction,
  GET_HEAT_GENERATOR_SUCCESS,
  GetHeatGeneratorAction,
  GetHeatGeneratorSuccessAction,
} from '../../../../state/ht-masterdata.action';
import cloneDeep from 'lodash/cloneDeep';
import { HtMasterdataBaseFormComponent } from '../../../ht-masterdata-base-form/ht-masterdata-base-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { generateEmptyLocalizations } from '../../../../../shared/components/localized-values/generateEmptyLocalizations';
import { checkSpecialCharacters } from '../../../../../shared/utils/validation-util';
import { TranslateService } from '@ngx-translate/core';
import { SubProductConnectionViewModel } from '../../../../shared/types/ht-masterdata-types';
import { getSubProductConnectionTypes, MasterDataLoadAction } from '../../../../../shared/store';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { HtMasterdataStringGenerationControlsComponent } from '../../ht-masterdata-string-generation-controls/ht-masterdata-string-generation-controls.component';
import LanguageEnum = LocalizedValueViewModel.LanguageEnum;
import { StringGenerationMapperService } from '../../../../../shared/components/string-generation-dialog/string-generation-mapper.service';

@Component({
  selector: 'ac-ht-masterdata-domestic-water-heating-form',
  templateUrl: './ht-masterdata-heat-generators-form.component.html',
  styleUrls: ['./ht-masterdata-heat-generators-form.component.sass'],
})
export class HtMasterdataHeatGeneratorsFormComponent
  extends HtMasterdataBaseFormComponent
  implements OnInit, IGuardDeactivation
{
  heatGenerator: HeatGeneratorViewModel = {
    LocalizedTitles: generateEmptyLocalizations(),
    LocalizedDataSheets: generateEmptyLocalizations(),
  } as HeatGeneratorViewModel;
  initialHeatGenerator: HeatGeneratorViewModel = cloneDeep(this.heatGenerator);
  heatingPowersRequiredness = {};

  @ViewChild('heatGeneratorForm')
  heatGeneratorForm: UntypedFormGroup;

  @ViewChild(HtMasterdataStringGenerationControlsComponent)
  public stringGenerationControlsComponent: HtMasterdataStringGenerationControlsComponent;

  onSaveStringGenerationSanitizeCallback: Subject<boolean> = new Subject<boolean>();

  constructor(
    private action$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private stringGenerationMapper: StringGenerationMapperService,
    public store: Store<IRootState>
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const url$: Observable<UrlSegment[]> = this.route.url;
    const params$: Observable<Params> = this.route.params;

    this.subscriptions.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        const urlPath: string = url[1].path.toString();

        if (urlPath === 'edit') {
          if (params && params.id) {
            this.formTitle = 'Edit heat generator';
            this.itemId = params.id;
            this.loadHeatGenerator();
            this.isNew = false;
          }
        } else {
          this.formTitle = 'Add heat generator';
        }
      })
    );

    this.subscriptions.add(
      this.action$
        .pipe(ofType(ADD_HEAT_GENERATOR_SUCCESS, EDIT_HEAT_GENERATOR_SUCCESS), take(1))
        .subscribe(() => {
          this.store.dispatch(new MasterDataLoadAction());
          this.onBack();
        })
    );
  }

  onStringGenerationDialogClosed(dialogData: StringGeneration): void {
    if (!dialogData || dialogData?.Blocks?.length === 0) {
      return;
    }

    this.heatGenerator.LocalizedDataSheets =
      this.stringGenerationMapper.mapRawDataToLocalizedDataSheet(
        this.heatGenerator.LocalizedDataSheets,
        dialogData.Language as LanguageEnum,
        dialogData.RawData
      );

    this.heatGenerator.StringGenerationContainer =
      this.stringGenerationMapper.mapDialogDataToContainer(
        dialogData,
        this.heatGenerator.StringGenerationContainer
      );
  }

  onHeatingTypeChange(event: MatSelectChange): void {
    this.updateRequiredHeatingPowers(event.value);
  }

  updateRequiredHeatingPowers(heatingType: string): void {
    this.heatingPowersRequiredness['maxHeatingPowerA7W35'] =
      heatingType === 'AirWaterHeatPumpOutside' ||
      heatingType === 'AirWaterHeatPumpSplit' ||
      heatingType === 'AirWaterHeatPumpInside';
    this.heatingPowersRequiredness['maxHeatingPowerA7W55'] =
      heatingType === 'AirWaterHeatPumpOutside' ||
      heatingType === 'AirWaterHeatPumpSplit' ||
      heatingType === 'AirWaterHeatPumpInside';
    this.heatingPowersRequiredness['maxHeatingPowerB0W35'] = heatingType === 'BrineWaterHeatPump';
    this.heatingPowersRequiredness['maxHeatingPowerB0W55'] = heatingType === 'BrineWaterHeatPump';
    this.heatingPowersRequiredness['maxCoolingPowerB0W35'] = heatingType === 'BrineWaterHeatPump';
    this.heatingPowersRequiredness['maxHeatingPowerOil5530'] = heatingType === 'OilBoiler';
    this.heatingPowersRequiredness['maxHeatingPowerGasP5030'] =
      heatingType === 'GasBoilerWall' || heatingType === 'GasBoilerFloor';
    this.heatingPowersRequiredness['maxHeatingPowerWood'] = heatingType === 'PelletsBoiler';
  }

  isFormValid(): boolean {
    return (
      this.heatGeneratorForm &&
      this.heatGeneratorForm.valid &&
      this.heatGenerator !== undefined &&
      this.heatGenerator.Id &&
      checkSpecialCharacters(this.heatGenerator.Id)
    );
  }

  onBack(): void {
    this.router.navigate(['/ht-masterdata/heat-generators/overview']);
  }

  onSave(): void {
    this.initialHeatGenerator = cloneDeep(this.heatGenerator);
    this.onSaveStringGenerationSanitizeCallback.next(true);

    const newPostPutHeatGeneratorRequest: PostPutHeatGeneratorRequest = {
      Id: this.heatGenerator.Id,
      TechnicalTitle: this.heatGenerator.TechnicalTitle,
      ArticleNumber: this.heatGenerator.ArticleNumber,
      LocalizedTitles: this.heatGenerator.LocalizedTitles,
      StringGenerationContainer: this.heatGenerator.StringGenerationContainer,
      Description: this.heatGenerator.Description,
      IsActive: this.heatGenerator.IsActive,
      MandatorIds: this.heatGenerator.MandatorIds,
      HeatingType: this.heatGenerator.HeatingType,
      Manufacturer: this.heatGenerator.Manufacturer,
      SubProductConnectionType: this.heatGenerator.SubProductConnectionType,
      MinRequiredRegisterSize: this.heatGenerator.MinRequiredRegisterSize,
      MaxRequiredRegisterSize: this.heatGenerator.MaxRequiredRegisterSize,
      NoiseLevelES1Q2: this.heatGenerator.NoiseLevelES1Q2,
      NoiseLevelES1Q4: this.heatGenerator.NoiseLevelES1Q4,
      NoiseLevelES1Q8: this.heatGenerator.NoiseLevelES1Q8,
      NoiseLevelES2Q2: this.heatGenerator.NoiseLevelES2Q2,
      NoiseLevelES2Q4: this.heatGenerator.NoiseLevelES2Q4,
      NoiseLevelES2Q8: this.heatGenerator.NoiseLevelES2Q8,
      NoiseLevelES3Q2: this.heatGenerator.NoiseLevelES3Q2,
      NoiseLevelES3Q4: this.heatGenerator.NoiseLevelES3Q4,
      NoiseLevelES3Q8: this.heatGenerator.NoiseLevelES3Q8,
      NoiseLevelES4Q2: this.heatGenerator.NoiseLevelES4Q2,
      NoiseLevelES4Q4: this.heatGenerator.NoiseLevelES4Q4,
      NoiseLevelES4Q8: this.heatGenerator.NoiseLevelES4Q8,
      MaxHeatingPowerA7W35: this.heatGenerator.MaxHeatingPowerA7W35,
      MaxHeatingPowerA7W55: this.heatGenerator.MaxHeatingPowerA7W55,
      MaxHeatingPowerB0W35: this.heatGenerator.MaxHeatingPowerB0W35,
      MaxCoolingPowerB0W35: this.heatGenerator.MaxCoolingPowerB0W35,
      CoolingFunction: this.heatGenerator.CoolingFunction,
      MaxHeatingPowerB0W55: this.heatGenerator.MaxHeatingPowerB0W55,
      MaxHeatingPowerOil5530: this.heatGenerator.MaxHeatingPowerOil5530,
      MaxHeatingPowerGasP5030: this.heatGenerator.MaxHeatingPowerGasP5030,
      MaxHeatingPowerWood: this.heatGenerator.MaxHeatingPowerWood,
      DomesticWaterHeatingMinVolume: this.heatGenerator.DomesticWaterHeatingMinVolume,
      DomesticWaterHeatingMaxVolume: this.heatGenerator.DomesticWaterHeatingMaxVolume,
      WaterTanksMinVolume: this.heatGenerator.WaterTanksMinVolume,
      WaterTanksMaxVolume: this.heatGenerator.WaterTanksMaxVolume,
      ArViewerUrl: this.heatGenerator.ArViewerUrl,
    } as PostPutHeatGeneratorRequest;

    if (this.isNew) {
      this.store.dispatch(new AddHeatGeneratorAction(newPostPutHeatGeneratorRequest));
    } else {
      this.store.dispatch(new EditHeatGeneratorAction(newPostPutHeatGeneratorRequest));
    }
  }

  protected convertSubProductConnectionTypes(
    subProductConnectionTypes: HeatingSubProductConnectionViewModel[]
  ): SubProductConnectionViewModel[] {
    const inUseSuffix = this.translate.instant('HT_MASTERDATA.SUB_PRODUCT_CONNECTION_IN_USE');
    return subProductConnectionTypes.map(connection => ({
      key: connection.Key,
      displayValue: this.isConnectionInUse(connection)
        ? connection.Key + inUseSuffix
        : connection.Key,
    }));
  }

  private isConnectionInUse(connection: HeatingSubProductConnectionViewModel): boolean {
    return (
      connection.InUseForMandators.filter(v => this.heatGenerator.MandatorIds?.includes(v)).length >
        0 && this.initialHeatGenerator.SubProductConnectionType !== connection.Key
    );
  }

  private loadHeatGenerator(): void {
    this.store.dispatch(new GetHeatGeneratorAction(this.itemId));

    const loadHeatGeneratorSuccess$ = this.action$.pipe(
      ofType(GET_HEAT_GENERATOR_SUCCESS),
      take(1)
    );
    const subProductConnectionTypes$ = this.store.select(getSubProductConnectionTypes);
    this.subscriptions.add(
      combineLatest([loadHeatGeneratorSuccess$, subProductConnectionTypes$]).subscribe(
        ([action, subProductConnectionTypes]: [
          GetHeatGeneratorSuccessAction,
          HeatingSubProductConnectionViewModel[]
        ]) => {
          this.heatGenerator = cloneDeep(action.payload as PostPutHeatGeneratorRequest);
          this.initialHeatGenerator = cloneDeep(this.heatGenerator);
          this.subProductConnectionTypes =
            this.convertSubProductConnectionTypes(subProductConnectionTypes);
          this.updateRequiredHeatingPowers(this.heatGenerator.HeatingType);
        }
      )
    );
  }

  get canDeactivateSafely(): boolean {
    this.initialHeatGenerator.StringGenerationContainer = this.stringGenerationMapper.unsetRawData(
      this.initialHeatGenerator.StringGenerationContainer
    );

    return isEqual(this.heatGenerator, this.initialHeatGenerator);
  }
}
