<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ 'MAIN.IGH_THEIA_CONVERTER' | translate }}
  </div>
  <div>
    <button mat-button (click)="sendFilesToConvert()" [disabled]="!isFormValid()">
      {{ 'COMMON.BTN_CONVERT' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <h2>
      {{ 'MAIN.IGH_THEIA_CONVERTER' | translate }}
    </h2>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="15px">
        <h3>{{ 'IGH_TO_THEIA_CONVERTER.IKK_FILE_LABEL' | translate }}</h3>
        <ac-file-select (onFileSelect)="onFileSelect($event)"></ac-file-select>
        <div>
          <mat-divider></mat-divider>
        </div>
        <h3>{{ 'IGH_TO_THEIA_CONVERTER.IGH_FILE_LABEL' | translate }}</h3>
        <ac-file-select
          (onFileSelect)="onFileSelect($event, fileanguage.DE)"
          [fileLanguage]="fileanguage.DE"></ac-file-select>
        <ac-file-select
          (onFileSelect)="onFileSelect($event, fileanguage.FR)"
          [fileLanguage]="fileanguage.FR"></ac-file-select>
        <ac-file-select
          (onFileSelect)="onFileSelect($event, fileanguage.IT)"
          [fileLanguage]="fileanguage.IT"></ac-file-select>
      </div>
    </mat-card-content>
  </mat-card>
</div>
