import { Component, OnDestroy, OnInit, reflectComponentType, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DeleteInverterAction, GetInvertersAction } from '../../../../state/masterdata.action';
import { Store } from '@ngrx/store';
import { InverterListViewModel } from '../../../../../shared/apis/advis';
import { IPageSettings } from '../../../../../shared/interfaces/Settings';
import { OverviewControl } from '../../../../../shared/components/overview-filter/Type';
import { Subscription } from 'rxjs';
import { SetPageSettingsAction } from '../../../../../shared/store';
import { Router } from '@angular/router';
import { getInverterFiltered } from '../../../../state/masterdata.selectors';
import { IRootState } from '../../../../../root.state';
import { OverviewBaseComponent } from '../../../../../shared/components/overview-filter/overviewBaseComponent';

@Component({
  selector: 'ac-masterdata-inverters-overview',
  templateUrl: './masterdata-inverters-overview.component.html',
  styleUrls: ['./masterdata-inverters-overview.component.scss'],
})
export class MasterdataInvertersOverviewComponent
  extends OverviewBaseComponent
  implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  invertersFiltered: InverterListViewModel[] = [];

  displayedColumns: string[] = ['Id', 'Description', 'IsActive', 'Actions'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSettings: IPageSettings;

  constructor(store: Store<IRootState>, private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    this.refresh();

    this.overviewFilterInit(
      reflectComponentType(MasterdataInvertersOverviewComponent).selector,
      [
        {
          key: 'Description',
          translateKey: 'Name',
          control: OverviewControl.STRING,
          isServerSide: true,
        },
        {
          key: 'IsActive',
          translateKey: 'Short name',
          control: OverviewControl.BOOLEAN,
          isServerSide: true,
        },
      ],
      this.subscription
    );

    this.subscription.add(
      this.store.select(getInverterFiltered).subscribe((inverters: InverterListViewModel[]) => {
        this.invertersFiltered = inverters;
        this.dataSource = new MatTableDataSource(this.invertersFiltered);
        this.dataSource.paginator = this.paginator;

        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            default:
              return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.clientSideFiltering();
      })
    );
  }

  changePaging(e: PageEvent): void {
    this.pageSettings.pagingSetting.pageIndex = e.pageIndex;
    this.pageSettings.pagingSetting.pageSize = e.pageSize;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  changeSort(e: Sort): void {
    this.pageSettings.sortSetting = e;
    this.store.dispatch(new SetPageSettingsAction(this.pageSettings));
  }

  refresh(): void {
    this.store.dispatch(new GetInvertersAction());
  }

  navigateToEdit(inverterId: string): void {
    this.router.navigate(['/pvmasterdata/inverters/edit', inverterId]);
  }

  delete(inverterId: string): void {
    this.store.dispatch(new DeleteInverterAction(inverterId));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clientSideFiltering(): void {
    this.dataSource.filter = this.pageSettings.overviewFilter.tableGlobalFilter || '';
  }

  serverSideFiltering(): void {
    // empty
  }
}
