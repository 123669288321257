<ng-container>
  <div style="min-width: 50vw; max-height: 90vh">
    <h3>String Generation - {{ currentLanguage }}</h3>

    <div fxLayout="row">
      <mat-form-field>
        <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

        <mat-select
          name="stringGenerationMandatorIds"
          required
          [ngModel]="currentMandatorId"
          #stringGenerationMandatorIds
          (selectionChange)="onMandatorChange($event)">
          <mat-option
            *ngFor="let mandator of mandators"
            [value]="mandator.Id"
            [disabled]="!mandator.IsActive"
            >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="currentMandatorId">
      <mat-label>BaseUrl:</mat-label>
      <textarea matInput readonly>{{ this.mandatorBaseUrl }}</textarea>
    </mat-form-field>

    <mat-form-field
      *ngIf="!isNullOrUndefined(generatedStringPreview) && generatedStringPreview !== ''">
      <mat-label>Preview of generated string:</mat-label>
      <textarea matInput readonly>{{ generatedStringPreview }}</textarea>
    </mat-form-field>

    <div
      fxLayout="row"
      *ngIf="!isNullOrUndefined(generatedStringPreview) && generatedStringPreview !== ''"
      style="margin-bottom: 5px">
      <p fxFlex="10">Preview HTML:</p>
      <div
        *ngIf="!isNullOrUndefined(generatedStringPreview) && generatedStringPreview !== ''"
        [innerHTML]="generatedStringPreview"
        fxFlex="90"></div>
    </div>

    <mat-divider
      *ngIf="
        !isNullOrUndefined(generatedStringPreview) && generatedStringPreview !== ''
      "></mat-divider>

    <div *ngIf="blocks.length < 1">No blocks set / no string generated.</div>
    <br />
    <div style="max-height: 50vh; overflow-y: auto">
      <div *ngFor="let block of blocks; let index = index">
        <ac-string-generation-block [block]="block" [index]="index" [removeBlock]="removeBlock">
        </ac-string-generation-block>
      </div>
    </div>

    <div fxLayout="row" style="padding: 15px 0 5px 0">
      <mat-icon>info</mat-icon>
      <p style="margin: 3px 0 0 3px">
        Saving with the new String Generation Module will overwrite the existing Data Sheet
        Translation Values for this language
      </p>
    </div>

    <div fxLayout="row">
      <button mat-button (click)="accept()" color="primary">Accept</button>

      <button mat-button (click)="addBlock()">Add Block</button>

      <button mat-button (click)="generateStringPreview()">Preview</button>

      <button mat-button (click)="cancel()" style="justify-content: flex-end">Cancel</button>
    </div>
  </div>
</ng-container>
