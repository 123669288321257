<div class="main-container">
  <mat-toolbar
    class="mat-toolbar main-header production"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <img src="../../assets/images/theia_black.svg" class="application-logo" alt="Bouygues" />
      <h1 class="application-title">Admin Client</h1>
    </div>
  </mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="center center" class="auth-card-container">
    <mat-card fxFlex.gt-xs="400px" fxFlex.xs="200px">
      <mat-card-title class="mat-card-login-title">
        {{ 'AUTH.LOGIN' | translate }}
      </mat-card-title>
      <mat-card-content>
        <form #loginForm="ngForm" (keyup.enter)="onLogin()">
          <div class="margin-bottom-16">
            <mat-form-field fxFlex="100%">
              <input
                matInput
                placeholder="{{ 'AUTH.USERNAME' | translate }}"
                [formControl]="username"
                required />
              <mat-error *ngIf="username.invalid">{{ getUsernameErrorMessage() }}</mat-error>
              <span matPrefix><mat-icon>person</mat-icon></span>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field fxFlex="100%">
              <input
                matInput
                placeholder="{{ 'AUTH.PASSWORD' | translate }}"
                [formControl]="password"
                type="password"
                required />
              <mat-error *ngIf="password.invalid">{{ getPasswordErrorMessage() }}</mat-error>
              <span matPrefix><mat-icon>lock</mat-icon></span>
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>

      <mat-card-actions align="end" class="padding-16">
        <!--            <span float="left" style="margin-right: 10px !important; margin-top: 8px !important;">-->
        <!--              <a *ngIf="environment.isDev()"-->
        <!--                 href="https://admin-dev.helion.ch/account/forgotpassword">{{ 'AUTH.FORGOT_PASSWORD' | translate }}</a>-->
        <!--              <a *ngIf="environment.isTest()"-->
        <!--                 href="https://admin-test.helion.ch/account/forgotpassword">{{ 'AUTH.FORGOT_PASSWORD' | translate }}</a>-->
        <!--              <a *ngIf="environment.isStaging()"-->
        <!--                 href="https://admin-test.helion.ch/account/forgotpassword">{{ 'AUTH.FORGOT_PASSWORD' | translate }}</a>-->
        <!--              <a *ngIf="environment.isProd()"-->
        <!--                 href="https://admin.helion.ch/account/forgotpassword">{{ 'AUTH.FORGOT_PASSWORD' | translate }}</a>-->
        <!--            </span>-->
        <button mat-raised-button data-cy="loginButtonOnLogin" color="accent" (click)="onLogin()">
          {{ 'AUTH.SIGN_IN' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
