<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ userFormTitle }}
  </div>
  <div *ngIf="user">
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button
      mat-button
      color="primary"
      [disabled]="!isFormValid && !isPictureChange"
      (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <h2>
      {{ userFormTitle }}
    </h2>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="addressForm"
        #addressForm="ngForm"
        *ngIf="user">
        <div>
          <!--Mandators-->
          <mat-form-field fxFlex="100%">
            <mat-label>Mandator</mat-label>
            <mat-select name="mandatorMap" required [(ngModel)]="user.MandatorId" #mandatorMap>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <!--Mandators-->
          <mat-form-field fxFlex="100%">
            <mat-label>Mandators in responsibility</mat-label>
            <mat-select
              name="mandatorsMap"
              multiple
              [(ngModel)]="user.MandatorsInResponsibility"
              #mandatorMap>
              <mat-option
                *ngFor="let mandator of mandators"
                [disabled]="user.MandatorId === mandator.Id || !mandator.IsActive"
                [value]="mandator.Id"
                >{{ mandator.Name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout.sm="row" fxLayoutGap="15px">
          <!--Roles-->
          <mat-form-field fxFlex="50%">
            <mat-label>{{ 'USERS.ROLE' | translate }}</mat-label>
            <mat-select name="roleMap" multiple required [(ngModel)]="user.Roles" #roleMap>
              <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!--Org units-->
          <mat-form-field fxFlex="50%">
            <mat-label>{{ 'USERS.ORG_UNIT' | translate }}</mat-label>
            <mat-select
              name="orgUnitMap"
              required
              [(ngModel)]="user.OrganisationUnitId"
              #orgUnitMap>
              <mat-option
                *ngFor="let orgUnit of organisationUnits"
                [value]="orgUnit.Id"
                [disabled]="!orgUnit.IsActive"
                >{{ orgUnit.Name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout.sm="row" fxLayoutGap="15px">
          <div fxLayout="column" fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ 'USERS.FIRSTNAME' | translate }}"
                type="text"
                maxlength="30"
                name="firstName"
                required
                [(ngModel)]="user.FirstName"
                #firstName />
            </mat-form-field>
            <!--<mat-error style="font-size: 10px; margin-top: -1.25em;" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">-->
            <!--Name is required.-->
            <!--</mat-error>-->
          </div>
          <mat-form-field fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <input
              matInput
              placeholder="{{ 'USERS.LASTNAME' | translate }}"
              type="text"
              maxlength="30"
              name="lastName"
              required
              [(ngModel)]="user.LastName"
              #lastName />
          </mat-form-field>
        </div>
        <div fxLayout.sm="row" fxLayoutGap="15px">
          <mat-form-field fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <input
              matInput
              placeholder="{{ 'USERS.PHONENUMBER' | translate }}"
              maxlength="100"
              name="phone"
              required
              [(ngModel)]="user.PhoneNumber"
              #phoneNumber />
          </mat-form-field>
          <mat-form-field fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <input
              matInput
              placeholder="{{ 'USERS.CLOUD_ID' | translate }}"
              type="text"
              name="cloudId"
              [(ngModel)]="user.CloudId" />
          </mat-form-field>
        </div>
        <div fxLayout.sm="row" fxLayoutGap="15px">
          <mat-form-field fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <input
              matInput
              placeholder="{{ 'USERS.EMAIL' | translate }}"
              type="email"
              email="true"
              name="email"
              required
              [(ngModel)]="user.Email"
              #email />
          </mat-form-field>
          <mat-form-field fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <input
              autocomplete="new-username"
              matInput
              placeholder="{{ 'USERS.USERNAME' | translate }}"
              type="text"
              name="username"
              required
              [disabled]="!isNew"
              [(ngModel)]="user.UserName" />
          </mat-form-field>
        </div>
        <div fxLayout *ngIf="isNew">
          <mat-form-field fxFlex="100%">
            <input
              autocomplete="new-password"
              matInput
              placeholder="{{ 'USERS.PASSWORD' | translate }}"
              type="password"
              name="password"
              required
              [(ngModel)]="userPassword"
              #password />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox
            fxFlex.lt-md="50%"
            fxFlex.gt-sm="50%"
            (change)="onSendEmailOnLeadAssignedChange($event.checked)"
            [checked]="user.SendEmailOnLeadAssigned">
            {{ 'USERS.SEND_EMAIL_ON_LEAD_ASSIGNED' | translate }}
          </mat-checkbox>
        </div>
        <div fxLayout.sm="column" fxLayoutGap="25px">
          <mat-form-field fxFlex="100%">
            <input
              #companyName
              matInput
              placeholder="{{ 'USERS.COMPANY_ADDRESS.COMPANY_NAME' | translate }}"
              type="text"
              maxlength="100"
              name="CompanyName"
              [(ngModel)]="user.CompanyAddress.CompanyName" />
          </mat-form-field>
        </div>

        <div fxLayout.sm="column" fxLayoutGap="25px">
          <mat-form-field fxFlex="100%">
            <input
              #jobTitle
              matInput
              placeholder="{{ 'USERS.COMPANY_ADDRESS.JOB_TITLE' | translate }}"
              type="text"
              maxlength="100"
              name="JobTitle"
              [(ngModel)]="user.JobTitle" />
          </mat-form-field>
        </div>

        <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
          <mat-form-field fxFlex="100%">
            <input
              #street
              matInput
              placeholder="{{ 'USERS.COMPANY_ADDRESS.STREET' | translate }}"
              type="text"
              maxlength="100"
              name="Street"
              [(ngModel)]="user.CompanyAddress.Street" />
          </mat-form-field>
        </div>

        <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
          <mat-form-field fxFlex="100%">
            <input
              #zipCode
              matInput
              placeholder="{{ 'USERS.COMPANY_ADDRESS.ZIP_CODE' | translate }}"
              type="text"
              name="ZipCode"
              [(ngModel)]="user.CompanyAddress.ZipCode" />
          </mat-form-field>

          <mat-form-field fxFlex.gt-sm="100%">
            <input
              #city
              matInput
              placeholder="{{ 'USERS.COMPANY_ADDRESS.CITY' | translate }}"
              type="text"
              maxlength="100"
              name="City"
              [(ngModel)]="user.CompanyAddress.City" />
          </mat-form-field>
        </div>

        <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
          <div fxFlex="100%">
            <ac-user-picture
              [titleKey]="'USERS.PICTURE_FILE'"
              [(pictureUrl)]="this.pictureUrl"
              [(picture)]="this.newPicture"
              (pictureChange)="onPictureChange()"
              (onDelete)="onPictureDelete($event)"></ac-user-picture>
          </div>
        </div>

        <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
          <div fxFlex="100%">
            <ac-user-picture
              [titleKey]="'USERS.SIGNATURE_FILE'"
              [(pictureUrl)]="this.signatureUrl"
              [(picture)]="this.newSignature"
              (pictureChange)="onPictureChange()"
              (onDelete)="onSignatureDelete($event)"></ac-user-picture>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
