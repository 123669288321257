/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EmailTemplateValidationResult } from '../model/emailTemplateValidationResult';
import { EmailTemplateViewModel } from '../model/emailTemplateViewModel';
import { EmailTemplatesRequest } from '../model/emailTemplatesRequest';
import { PageResultViewModelEmailTemplateViewModel } from '../model/pageResultViewModelEmailTemplateViewModel';
import { PostPutEmailTemplateRequest } from '../model/postPutEmailTemplateRequest';
import { SendGridTemplateViewModel } from '../model/sendGridTemplateViewModel';
import { UserRequest } from '../model/userRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EmailTemplateService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete a Email template  It is not possible to delete the password reset template
     * 
     * @param templateId the id of the Email template to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesDeleteTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailTemplatesDeleteTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailTemplatesDeleteTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailTemplatesDeleteTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling emailTemplatesDeleteTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/admin/emailtemplates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of dynamic email templates from sendgrid  There are always pairs of Id and Title, to show in the dropdown
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesGetSendGridTemplates(observe?: 'body', reportProgress?: boolean): Observable<Array<SendGridTemplateViewModel>>;
    public emailTemplatesGetSendGridTemplates(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SendGridTemplateViewModel>>>;
    public emailTemplatesGetSendGridTemplates(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SendGridTemplateViewModel>>>;
    public emailTemplatesGetSendGridTemplates(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SendGridTemplateViewModel>>(`${this.basePath}/api/admin/emailtemplates/sendgrid-templates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a specific template by id
     * 
     * @param templateId the id of the Email template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesGetTemplate(templateId: number, observe?: 'body', reportProgress?: boolean): Observable<EmailTemplateViewModel>;
    public emailTemplatesGetTemplate(templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailTemplateViewModel>>;
    public emailTemplatesGetTemplate(templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailTemplateViewModel>>;
    public emailTemplatesGetTemplate(templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling emailTemplatesGetTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<EmailTemplateViewModel>(`${this.basePath}/api/admin/emailtemplates/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets filtered Email templates
     * 
     * @param Title 
     * @param Language 
     * @param MandatorIds 
     * @param TradeTypeCodes 
     * @param ShowForNbo 
     * @param ShowForBo 
     * @param ShowForProject 
     * @param Trigger 
     * @param PageIndex 
     * @param PageSize 
     * @param SortField 
     * @param SortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, TradeTypeCodes?: string, ShowForNbo?: boolean, ShowForBo?: boolean, ShowForProject?: boolean, Trigger?: 'PasswordReset' | 'LeadAssigned' | 'DefaultNbo' | 'DefaultBo' | 'DefaultProject' | 'NboLeadCreated' | 'ImageUploaded' | 'MtSapIntegration' | 'MtCustomerNotification' | 'ProjectArchived' | 'TaskAssigned' | 'DebitorAddressAdded' | 'ObjectAddressAdded' | 'ServiceTechnicianAddressAdded' | 'CarDealerAddressAdded' | 'HeatingInstallerAddressAdded' | 'LeadDelegated' | 'AddExternalManagedContact' | 'SapDebtorError' | 'SynchronizationError' | 'LeadTaskChanged' | 'ProjectStepCompleted' | 'ProjectWon' | 'ProjectAssigned' | 'ProjectInProgress' | 'ProjectInOperation' | 'ProjectAccounted', PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'ShowForProject' | 'ShowForBo' | 'ShowForNbo' | 'Trigger', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<PageResultViewModelEmailTemplateViewModel>;
    public emailTemplatesGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, TradeTypeCodes?: string, ShowForNbo?: boolean, ShowForBo?: boolean, ShowForProject?: boolean, Trigger?: 'PasswordReset' | 'LeadAssigned' | 'DefaultNbo' | 'DefaultBo' | 'DefaultProject' | 'NboLeadCreated' | 'ImageUploaded' | 'MtSapIntegration' | 'MtCustomerNotification' | 'ProjectArchived' | 'TaskAssigned' | 'DebitorAddressAdded' | 'ObjectAddressAdded' | 'ServiceTechnicianAddressAdded' | 'CarDealerAddressAdded' | 'HeatingInstallerAddressAdded' | 'LeadDelegated' | 'AddExternalManagedContact' | 'SapDebtorError' | 'SynchronizationError' | 'LeadTaskChanged' | 'ProjectStepCompleted' | 'ProjectWon' | 'ProjectAssigned' | 'ProjectInProgress' | 'ProjectInOperation' | 'ProjectAccounted', PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'ShowForProject' | 'ShowForBo' | 'ShowForNbo' | 'Trigger', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageResultViewModelEmailTemplateViewModel>>;
    public emailTemplatesGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, TradeTypeCodes?: string, ShowForNbo?: boolean, ShowForBo?: boolean, ShowForProject?: boolean, Trigger?: 'PasswordReset' | 'LeadAssigned' | 'DefaultNbo' | 'DefaultBo' | 'DefaultProject' | 'NboLeadCreated' | 'ImageUploaded' | 'MtSapIntegration' | 'MtCustomerNotification' | 'ProjectArchived' | 'TaskAssigned' | 'DebitorAddressAdded' | 'ObjectAddressAdded' | 'ServiceTechnicianAddressAdded' | 'CarDealerAddressAdded' | 'HeatingInstallerAddressAdded' | 'LeadDelegated' | 'AddExternalManagedContact' | 'SapDebtorError' | 'SynchronizationError' | 'LeadTaskChanged' | 'ProjectStepCompleted' | 'ProjectWon' | 'ProjectAssigned' | 'ProjectInProgress' | 'ProjectInOperation' | 'ProjectAccounted', PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'ShowForProject' | 'ShowForBo' | 'ShowForNbo' | 'Trigger', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageResultViewModelEmailTemplateViewModel>>;
    public emailTemplatesGetTemplates(Title?: string, Language?: 'DE' | 'FR' | 'IT' | 'EN', MandatorIds?: string, TradeTypeCodes?: string, ShowForNbo?: boolean, ShowForBo?: boolean, ShowForProject?: boolean, Trigger?: 'PasswordReset' | 'LeadAssigned' | 'DefaultNbo' | 'DefaultBo' | 'DefaultProject' | 'NboLeadCreated' | 'ImageUploaded' | 'MtSapIntegration' | 'MtCustomerNotification' | 'ProjectArchived' | 'TaskAssigned' | 'DebitorAddressAdded' | 'ObjectAddressAdded' | 'ServiceTechnicianAddressAdded' | 'CarDealerAddressAdded' | 'HeatingInstallerAddressAdded' | 'LeadDelegated' | 'AddExternalManagedContact' | 'SapDebtorError' | 'SynchronizationError' | 'LeadTaskChanged' | 'ProjectStepCompleted' | 'ProjectWon' | 'ProjectAssigned' | 'ProjectInProgress' | 'ProjectInOperation' | 'ProjectAccounted', PageIndex?: number, PageSize?: number, SortField?: 'None' | 'Id' | 'Title' | 'Language' | 'ShowForProject' | 'ShowForBo' | 'ShowForNbo' | 'Trigger', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Title !== undefined && Title !== null) {
            queryParameters = queryParameters.set('Title', <any>Title);
        }
        if (Language !== undefined && Language !== null) {
            queryParameters = queryParameters.set('Language', <any>Language);
        }
        if (MandatorIds !== undefined && MandatorIds !== null) {
            queryParameters = queryParameters.set('MandatorIds', <any>MandatorIds);
        }
        if (TradeTypeCodes !== undefined && TradeTypeCodes !== null) {
            queryParameters = queryParameters.set('TradeTypeCodes', <any>TradeTypeCodes);
        }
        if (ShowForNbo !== undefined && ShowForNbo !== null) {
            queryParameters = queryParameters.set('ShowForNbo', <any>ShowForNbo);
        }
        if (ShowForBo !== undefined && ShowForBo !== null) {
            queryParameters = queryParameters.set('ShowForBo', <any>ShowForBo);
        }
        if (ShowForProject !== undefined && ShowForProject !== null) {
            queryParameters = queryParameters.set('ShowForProject', <any>ShowForProject);
        }
        if (Trigger !== undefined && Trigger !== null) {
            queryParameters = queryParameters.set('Trigger', <any>Trigger);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageResultViewModelEmailTemplateViewModel>(`${this.basePath}/api/admin/emailtemplates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesPostEmailTemplatesRequestMock(body?: EmailTemplatesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailTemplatesPostEmailTemplatesRequestMock(body?: EmailTemplatesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailTemplatesPostEmailTemplatesRequestMock(body?: EmailTemplatesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailTemplatesPostEmailTemplatesRequestMock(body?: EmailTemplatesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/emailtemplates/emailTemplatesRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a Email template
     * 
     * @param body the new Email template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesPostTemplate(body?: PostPutEmailTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<EmailTemplateViewModel>;
    public emailTemplatesPostTemplate(body?: PostPutEmailTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailTemplateViewModel>>;
    public emailTemplatesPostTemplate(body?: PostPutEmailTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailTemplateViewModel>>;
    public emailTemplatesPostTemplate(body?: PostPutEmailTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EmailTemplateViewModel>(`${this.basePath}/api/admin/emailtemplates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesPostUserRequestRequestMock(body?: UserRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailTemplatesPostUserRequestRequestMock(body?: UserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailTemplatesPostUserRequestRequestMock(body?: UserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailTemplatesPostUserRequestRequestMock(body?: UserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/emailtemplates/userRequestmock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a Email template
     * 
     * @param templateId the id of the Email template to update
     * @param body the Email template data to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesPutTemplate(templateId: number, body?: PostPutEmailTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<EmailTemplateViewModel>;
    public emailTemplatesPutTemplate(templateId: number, body?: PostPutEmailTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailTemplateViewModel>>;
    public emailTemplatesPutTemplate(templateId: number, body?: PostPutEmailTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailTemplateViewModel>>;
    public emailTemplatesPutTemplate(templateId: number, body?: PostPutEmailTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling emailTemplatesPutTemplate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EmailTemplateViewModel>(`${this.basePath}/api/admin/emailtemplates/${encodeURIComponent(String(templateId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * An endpoint to validate the configuration, returns warnings and errors
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailTemplatesValidateTemplates(observe?: 'body', reportProgress?: boolean): Observable<Array<EmailTemplateValidationResult>>;
    public emailTemplatesValidateTemplates(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EmailTemplateValidationResult>>>;
    public emailTemplatesValidateTemplates(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EmailTemplateValidationResult>>>;
    public emailTemplatesValidateTemplates(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EmailTemplateValidationResult>>(`${this.basePath}/api/admin/emailtemplates/validate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
