<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ inverterFormTitle }}
  </div>
  <div>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card fxFlex="50">
    <h2>
      {{ inverterFormTitle }}
    </h2>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="15px"
        name="inverterForm"
        #inverterForm="ngForm"
        *ngIf="inverter || isNew">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.ID' | translate }}"
              type="text"
              name="id"
              required
              [disabled]="!isNew"
              [(ngModel)]="inverter.Id"
              #id />
            <mat-hint>{{ 'HT_MASTERDATA.NO_SPACES_DOTS_AND_SLASHES_IN_ID' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.ARTICLE_NUMBER' | translate }}"
              type="text"
              name="articleNumber"
              required
              [(ngModel)]="inverter.ArticleNumber"
              #articleNumber />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox id="isActive" name="isActive" [(ngModel)]="inverter.IsActive">{{
            'COMMON.IS_ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'INVERTERS.DESC' | translate }}"
              type="text"
              name="description"
              [(ngModel)]="inverter.Description"
              #description />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'INVERTERS.MIN_RANGE' | translate }}"
              type="number"
              name="minRange"
              required
              [(ngModel)]="inverter.MinRange"
              #minRange />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'INVERTERS.MAX_RANGE' | translate }}"
              type="number"
              name="maxRange"
              required
              [(ngModel)]="inverter.MaxRange"
              #maxRange />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'INVERTERS.NOMINAL_POWER' | translate }}"
              type="number"
              name="nominalPower"
              required
              [(ngModel)]="inverter.NominalPower" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label>Inverter type</mat-label>

            <mat-select
              name="inverterType"
              required
              [(ngModel)]="inverter.InverterType"
              #inverterType>
              <mat-option *ngFor="let x of inverterTypes" [value]="x">{{ x }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Inverter Manufacturer</mat-label>

            <mat-select
              name="inverterManufacturer"
              required
              [(ngModel)]="inverter.Manufacturer"
              #inverterSmartEnergy>
              <mat-option *ngFor="let x of inverterManufacturer" [value]="x">{{ x }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label>Inverter smart energy</mat-label>

            <mat-select
              name="inverterSmartEnergy"
              required
              [(ngModel)]="inverter.InverterSmartEnergy"
              #inverterSmartEnergy>
              <mat-option *ngFor="let x of inverterSmartEnergyTypes" [value]="x">{{
                x
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Inverter surge protection</mat-label>

            <mat-select
              name="inverterSurgeProtection"
              required
              [(ngModel)]="inverter.InverterSurgeProtection"
              #inverterSurgeProtection>
              <mat-option *ngFor="let x of inverterSurgeProtectionTypes" [value]="x">{{
                x
              }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'COMMON.ETURNITY_COMPONENT_ID' | translate }}</mat-label>

            <input
              matInput
              placeholder="{{ 'COMMON.ETURNITY_COMPONENT_ID' | translate }}"
              type="text"
              name="eturnityComponentId"
              [maxLength]="100"
              [(ngModel)]="inverter.EturnityComponentId" />
          </mat-form-field>
        </div>

        <ac-localized-values
          [localizedValues]="inverter.LocalizedTitles"
          [title]="'COMMON.TRANSLATION'">
        </ac-localized-values>

        <ac-localized-values
          [localizedValues]="inverter.LocalizedDataSheets"
          [title]="'COMMON.DATASHEET'">
        </ac-localized-values>

        <div>
          <mat-form-field fxFlex="100">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>

            <mat-select name="mandatorIds" multiple [(ngModel)]="inverter.MandatorIds" #mandatorIds>
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name + ' (' + mandator.ShortName + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'COMMON.AR_VIEWER_URL' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMMON.AR_VIEWER_URL' | translate }}"
              type="text"
              name="arViewerUrl"
              [maxLength]="2048"
              [(ngModel)]="inverter.ArViewerUrl" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
